import { Avatar, Stack, Typography } from '@mui/material'
import { formatDateShort } from '@trinity/utils'
import { useGlobalState } from '../../hooks'

export function ClientAvatar({ client }: { client: TrinityAPI.ClientExtendedType }) {
  const { onMobile } = useGlobalState()

  return (
    <Stack direction={{ mobile: 'column', tablet: 'row' }} alignItems='center' spacing={4}>
      <Avatar
        sx={{
          fontSize: 40,
          letterSpacing: 3.75,
          bgcolor: 'primary.main',
          p: 6,
          boxShadow: theme => theme.elevation.avatar,
        }}
      >
        {client.initials}
      </Avatar>
      <div>
        <Typography noWrap gutterBottom variant='h2' align={onMobile ? 'center' : 'left'}>
          {client.name}
        </Typography>
        <Typography variant='subtitle1' align={onMobile ? 'center' : 'left'}>
          Client since{' '}
          {client.createdAt
            ? formatDateShort(client.createdAt)
            : client.firstOrder
              ? formatDateShort(client.firstOrder)
              : 'Unknown'}
        </Typography>
      </div>
    </Stack>
  )
}
