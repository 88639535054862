import { useSearchParams } from 'react-router-dom'
import {
  Stack,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  capitalize,
  Select,
  MenuItem,
  Typography,
} from '@mui/material'
import { useOrderStatus } from './OrderStatusProvider'

export function OrderStatusFilters() {
  const [params, setParams] = useSearchParams()
  const { ALL, OPEN, COMPLETED, ASCENDING, DESCENDING, isFilterScope, currentScope, setScope } = useOrderStatus()

  const handleChange = (paramKey: string, newValue: string) => {
    setParams(
      params => {
        params.set(paramKey, newValue)
        return params
      },
      { replace: true },
    )
  }

  if (isFilterScope(currentScope)) {
    return (
      <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ width: 1 }}>
        <FormControl component='fieldset'>
          <RadioGroup
            row
            aria-label='order-filter'
            name='order-filter'
            value={currentScope}
            onChange={(_e, v) => setScope(v)}
          >
            <FormControlLabel value={ALL} control={<Radio size='small' />} label={capitalize(ALL)} />
            <FormControlLabel value={OPEN} control={<Radio size='small' />} label={capitalize(OPEN)} />
            <FormControlLabel value={COMPLETED} control={<Radio size='small' />} label={capitalize(COMPLETED)} />
          </RadioGroup>
        </FormControl>
        <FormControl sx={{ display: { mobile: 'none', desktop: 'initial' } }}>
          <Select
            displayEmpty
            variant='standard'
            value={params.get('sort') ?? DESCENDING}
            onChange={e => handleChange('sort', e.target.value)}
            inputProps={{ sx: { pb: 0 } }}
          >
            <MenuItem value={DESCENDING}>
              <Typography variant='body1'>Newest Orders</Typography>
            </MenuItem>
            <MenuItem value={ASCENDING}>
              <Typography variant='body1'>Oldest Orders</Typography>
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>
    )
  }

  return null
}
