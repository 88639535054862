import { Link, Outlet, useNavigate, useParams } from 'react-router-dom'
import Highlighter from 'react-highlight-words'
import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { Search } from '../../components'

export function OptionLibrary() {
  return (
    <Stack spacing={4} pt={{ mobile: 1, laptop: 8 }} px={{ mobile: 1, laptop: 4 }}>
      <Stack
        direction={{ mobile: 'column', laptop: 'row' }}
        spacing={4}
        alignItems='center'
        justifyContent='space-between'
      >
        <Typography variant='h2'>Options Library</Typography>
        <Box width={{ mobile: 1, laptop: 'unset' }}>
          <Search<TrinityAPI.OptionType> name='search'>
            {({ suggestion, searchTerm }) => <OptionSuggestion suggestion={suggestion} searchTerm={searchTerm} />}
          </Search>
        </Box>
      </Stack>
      <Divider />
      <Box>
        <Outlet />
      </Box>
    </Stack>
  )
}

function OptionSuggestion({ suggestion, searchTerm }: { suggestion: TrinityAPI.OptionType; searchTerm: string }) {
  return (
    <ListItem disablePadding>
      <ListItemButton
        component={Link}
        to={`/library/options/${suggestion.garmentType}/${suggestion.optionGroup.id}/${suggestion.id}`}
      >
        <ListItemText primaryTypographyProps={{ variant: 'body3' }}>
          <Highlighter searchWords={[searchTerm]} textToHighlight={suggestion.description} highlightTag='strong' />
        </ListItemText>
      </ListItemButton>
    </ListItem>
  )
}

interface GarmentOptionsProps {
  options: TrinityAPI.OptionGroupType[] | TrinityAPI.OptionType[]
}

export function GarmentOptions({ options }: GarmentOptionsProps) {
  const navigate = useNavigate()

  return (
    <Stack>
      <OptionsBreadcrumbs />
      <Grid
        container
        direction={{ mobile: 'column', laptop: 'row' }}
        alignItems='center'
        rowSpacing={{ mobile: 4, laptop: 10 }}
        columnSpacing={10}
        pt={6}
      >
        {options.map(option => (
          <Grid item key={option.id} xs={1} md={4} width={1}>
            <Button
              variant='outlined'
              onClick={() => navigate(String(option.id))}
              sx={{
                borderRadius: 25.5,
                width: 1,
                py: 3.5,
                boxShadow: theme => theme.elevation.card2,
                border: 'none',
                '&:hover': { border: 'none' },
              }}
            >
              {isOption(option) ? option.description : option.name}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Stack>
  )
}

export function GarmentOptionValues({ optionValues }: { optionValues: TrinityAPI.OptionValueType[] }) {
  return (
    <Stack>
      <OptionsBreadcrumbs />
      <Grid
        container
        direction={{ mobile: 'column', laptop: 'row' }}
        alignItems='center'
        rowSpacing={{ mobile: 4, laptop: 10 }}
        columnSpacing={10}
        pt={6}
      >
        {optionValues.map(optionValue => (
          <Grid item key={optionValue.id} xs={1} md={4} width={1}>
            <Stack spacing={3} alignItems='center' pb={5} px={3}>
              <img src={optionValue.image} alt={optionValue.description} />
              <Stack alignItems='center'>
                <Typography gutterBottom variant='body3'>
                  {optionValue.option.description}
                </Typography>
                <Typography variant='smallBody2'>{optionValue.description}</Typography>
              </Stack>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Stack>
  )
}

export function OptionsBreadcrumbs() {
  const navigate = useNavigate()
  const params = useParams()

  return (
    <Breadcrumbs sx={{ zIndex: 1 }}>
      <Typography variant='link' onClick={() => navigate('/library/options')}>
        Garment Type
      </Typography>
      <Typography
        variant={params.optionGroupId ? 'link' : 'body1'}
        onClick={() => (params.optionGroupId ? navigate(`/library/options/${params.garmentType}`) : null)}
      >
        Option Group
      </Typography>
      <Typography
        variant={params.optionId ? 'link' : 'body1'}
        onClick={() =>
          params.optionGroupId ? navigate(`/library/options/${params.garmentType}/${params.optionGroupId}`) : null
        }
      >
        Option
      </Typography>
    </Breadcrumbs>
  )
}

const isOption = (record: Record<string, unknown>): record is TrinityAPI.OptionType =>
  Object.prototype.hasOwnProperty.call(record, 'description')
