import { Button, CircularProgress, Stack, type ButtonProps } from '@mui/material'

export interface SubmitButtonProps extends Omit<ButtonProps, 'name'> {
  isLoading: boolean
  loadingText?: string
}

export function SubmitButton({ isLoading, loadingText = '', disabled, children, ...props }: SubmitButtonProps) {
  return (
    <Button disabled={disabled || isLoading} name='intent' {...props}>
      {isLoading ? (
        <Stack direction='row' spacing={2} alignItems='center'>
          <CircularProgress size={25} />
          {loadingText && <span>{loadingText}</span>}
        </Stack>
      ) : (
        children
      )}
    </Button>
  )
}
