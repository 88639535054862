import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Avatar, Button, Divider, Grid, Paper, Stack, Typography } from '@mui/material'
import { clientImagesUrl, clientMeasurementsUrl, formatDate, formatDateShort, getGarmentCount } from '@trinity/utils'
import { Email, LocationOn, Phone, type SvgIconComponent, Textsms } from '@mui/icons-material'
import { useGlobalState } from '../../hooks'
import { OrderStatus } from '../OrderStatus'

interface ClientProps {
  client: TrinityAPI.ClientExtendedType
  orders?: TrinityAPI.OrderHomeType[]
  delays?: number
}

export function Client({ client, orders = [], delays = 0 }: ClientProps) {
  const [params] = useSearchParams()

  return (
    <Stack>
      <ClientHeader client={client} />
      <Paper
        sx={{
          py: 5,
          px: { mobile: 3, tablet: 5 },
          mt: { tablet: -6 },
          border: { tablet: 'n100' },
          boxShadow: theme => ({
            mobile: 0,
            tablet: theme.elevation.card,
          }),
        }}
      >
        <Stack spacing={6} alignItems='center'>
          <Typography variant='h4' sx={{ alignSelf: { tablet: 'flex-start' } }}>
            Orders
          </Typography>
          <Stack
            spacing={{ mobile: 3, tablet: 6 }}
            justifyContent='center'
            alignItems='center'
            sx={{ pt: { mobile: 0, tablet: 2 }, width: { mobile: 1, tablet: 3 / 4 } }}
          >
            <OrderStatus>
              <OrderStatus.Tabs delays={delays} />
              {orders.length === 0 && <OrderStatus.NoOrdersFound />}
              <Stack spacing={1} sx={{ width: 1, pb: 10 }}>
                {orders.map(order => (
                  <OrderStatus.Accordion key={order.id} order={order}>
                    <Typography
                      noWrap
                      variant='h6'
                      sx={{ pl: { mobile: 0.5, tablet: 2 }, flex: 2, maxWidth: { mobile: 2 / 3, tablet: '100%' } }}
                    >
                      {params.get('scope') === 'incomplete' ? 'Started ' : ''}
                      {formatDate(order.orderedAt ? order.orderedAt : order.createdAt)}
                    </Typography>
                    <Typography
                      noWrap
                      align='right'
                      variant='subtitle1'
                      sx={{ flex: 2, maxWidth: { mobile: 170, laptop: '100%' } }}
                    >
                      {getGarmentCount(order.garments)}
                    </Typography>
                  </OrderStatus.Accordion>
                ))}
              </Stack>
            </OrderStatus>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  )
}

function ClientHeader({ client }: ClientProps) {
  const { onMobile, onSkiffle } = useGlobalState()
  const navigate = useNavigate()

  return (
    <Stack
      direction={{ mobile: 'column', tablet: 'row' }}
      alignItems={{ mobile: 'center', tablet: 'flex-start' }}
      justifyContent='space-between'
      spacing={6}
      sx={{
        px: { tablet: 12 },
        pt: 8,
        pb: { tablet: 20 },
        m: { mobile: 0, tablet: -4, desktop: -6 },
        bgcolor: { tablet: 'grey.50' },
      }}
    >
      <Stack spacing={{ mobile: 6, laptop: 2 }} alignItems={{ mobile: 'center', tablet: 'flex-start' }}>
        <Stack direction={{ mobile: 'column', tablet: 'row' }} alignItems='center' spacing={4}>
          <Avatar
            sx={{
              fontSize: 40,
              letterSpacing: 3.75,
              bgcolor: 'primary.main',
              p: 6,
              boxShadow: theme => theme.elevation.avatar,
            }}
          >
            {client.initials}
          </Avatar>
          <div>
            <Typography noWrap gutterBottom variant='h2' align={onMobile ? 'center' : 'left'}>
              {client.name}
            </Typography>
            <Typography variant='subtitle1' align={onMobile ? 'center' : 'left'}>
              Client since{' '}
              {client.createdAt
                ? formatDateShort(client.createdAt)
                : client.firstOrder
                  ? formatDateShort(client.firstOrder)
                  : 'Unknown'}
            </Typography>
          </div>
        </Stack>
        <ClientAddress client={client} />
      </Stack>
      <Stack spacing={2} alignItems='center' sx={{ width: { mobile: 3 / 4, tablet: 'auto' } }}>
        {!onSkiffle && (
          <Button
            fullWidth
            color='success'
            size={onMobile ? 'large' : 'medium'}
            component={Link}
            to={`/new-order?clientId=${client.id}`}
          >
            New Order
          </Button>
        )}
        <Button fullWidth variant='outlined' onClick={() => navigate('edit', { state: client })}>
          Edit Client
        </Button>
        <Button fullWidth variant='outlined' href={clientMeasurementsUrl(client.id)}>
          Measurements
        </Button>
        <Button fullWidth variant='outlined' href={clientImagesUrl(client.id)}>
          Images
        </Button>
        <Button fullWidth variant='outlined' onClick={() => navigate('closet')}>
          Cloud Closet
        </Button>
        {onMobile && <Divider sx={{ width: 1, pt: 2 }} />}
      </Stack>
    </Stack>
  )
}

function ClientAddress({ client }: ClientProps) {
  const { onMobile } = useGlobalState()
  const hasEmail = Boolean(client.email)
  const hasAddress = Boolean(client.address1) && Boolean(client.city) && Boolean(client.state) && Boolean(client.zip)
  const hasCell = Boolean(client.cell)
  const hasNoContactInfo = !hasEmail && !hasAddress && !hasCell

  if (onMobile) {
    return (
      <Stack component='address' direction='row' spacing={5}>
        <ClientInfoBlob shouldRender={hasCell} Icon={Phone} href={`tel:${client.cell}`} />
        <ClientInfoBlob shouldRender={hasEmail} Icon={Email} href={`mailto:${client.email}`} />
        <ClientInfoBlob shouldRender={hasCell} Icon={Textsms} href={`sms://${client.cell}`} />
      </Stack>
    )
  }

  if (hasNoContactInfo) {
    return (
      <Stack alignItems='center' sx={{ pl: 4, pt: 2 }}>
        <Typography gutterBottom variant='h5'>
          No Client Information
        </Typography>
        <Typography variant='body1'>Add info to a client's profile and it will show up here</Typography>
      </Stack>
    )
  }

  return (
    <Grid container component='address' columns={6} sx={{ fontStyle: 'normal' }} rowSpacing={4}>
      <ClientInfoBlob shouldRender={hasEmail} title='Email' Icon={Email}>
        <Typography variant='link' component='a' href={`mailto:${client.email}`}>
          {client.email}
        </Typography>
      </ClientInfoBlob>
      <ClientInfoBlob shouldRender={hasAddress} title='Address' Icon={LocationOn}>
        <Typography
          variant='link'
          component='a'
          href={`https://maps.google.com/maps?q=${client.address1} ${client.city} ${client.state} ${client.zip}`}
          target='_blank'
          rel='noreferrer'
        >
          {client.address1}
          {client.address1 && <br />}
          {client.address2}
          {client.address2 && <br />}
          {`${client.city}, ${client.state}  ${client.zip}`}
          {client.country && <br />}
          {client.country}
        </Typography>
      </ClientInfoBlob>
      <ClientInfoBlob shouldRender={hasCell} title='Cell' Icon={Phone}>
        <Typography variant='link' component='a' href={`tel:${client.cell}`}>
          {client.cell}
        </Typography>
      </ClientInfoBlob>
    </Grid>
  )
}

interface ClientInfoBlobProps {
  shouldRender: boolean
  title?: string
  Icon: SvgIconComponent
  href?: string
  children?: React.ReactNode
}

function ClientInfoBlob({ shouldRender, title = '', Icon, href = '', children = [] }: ClientInfoBlobProps) {
  const { onMobile } = useGlobalState()

  if (!shouldRender) return null

  if (onMobile) {
    return (
      <Avatar component='a' href={href} sx={{ bgcolor: 'common.white', border: 'primary', p: 3.5 }}>
        <Icon fontSize='large' color='primary' />
      </Avatar>
    )
  }

  return (
    <Grid item xs={3}>
      <Stack spacing={1}>
        <Typography variant='smallBody2' sx={{ pl: 5 }}>
          {title}
        </Typography>
        <Stack direction='row' spacing={2}>
          <Icon fontSize='small' />
          {children}
        </Stack>
      </Stack>
    </Grid>
  )
}
