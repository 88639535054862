import { useNavigate, Link } from 'react-router-dom'
import { Button, Typography } from '@mui/material'
import { formatDate } from '@trinity/utils'
import { Table } from '../../components'
import { useGlobalState } from '../../hooks'

interface ClientsTableProps {
  clients: TrinityAPI.ClientDashboardType[]
}

export function ClientsTable({ clients }: ClientsTableProps) {
  const { onMobile } = useGlobalState()
  const navigate = useNavigate()

  return (
    <Table
      cellBorders={!onMobile}
      spacing={onMobile ? 0 : 0.5}
      aria-label='Client List'
      sx={{
        ml: { mobile: '-1rem !important', tablet: '-2rem !important', laptop: 'auto !important' },
        width: { mobile: '109%', laptop: 1 },
      }}
    >
      <Table.Head>
        <Table.Row sx={{ '> :last-child': { textAlign: 'center' } }}>
          <Table.Cell>Client</Table.Cell>
          <Table.Cell>Orders</Table.Cell>
          {!onMobile && (
            <>
              <Table.Cell>Client Since</Table.Cell>
              <Table.Cell>Last Order</Table.Cell>
              <Table.Cell />
            </>
          )}
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {clients.map(client => (
          <Table.Row
            hover
            key={client.id}
            onClick={() => (onMobile ? navigate(`/clients/${client.id}`) : null)}
            sx={{ height: 76, cursor: { mobile: 'pointer', tablet: 'initial' } }}
          >
            <Table.Cell>
              <Typography variant='h6' sx={{ color: client.totalOrders > 0 ? 'grey.900' : 'grey.500' }}>
                {client.name}
              </Typography>
            </Table.Cell>
            <Table.Cell align={onMobile ? 'center' : 'left'}>{client.totalOrders}</Table.Cell>
            {!onMobile && (
              <>
                <Table.Cell>{client.firstOrder ? formatDate(client.firstOrder) : '--'}</Table.Cell>
                <Table.Cell>{client.lastOrder ? formatDate(client.lastOrder) : '--'}</Table.Cell>
                <Table.Cell align='center'>
                  <Button
                    component={Link}
                    size='small'
                    color='neutral'
                    variant='outlined'
                    to={`/clients/${client.id}`}
                    sx={{ mr: 1 }}
                  >
                    View Client
                  </Button>
                  <Button
                    component={Link}
                    size='small'
                    color='neutral'
                    variant='outlined'
                    to={`/clients/${client.id}/closet`}
                  >
                    View Closet
                  </Button>
                </Table.Cell>
              </>
            )}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}
