import { type Theme } from '@mui/material'

export const getBorders = (theme: Theme) => ({
  success: `1px solid ${theme.palette.success.main}`,
  primary: `1px solid ${theme.palette.primary.main}`,
  n100: `1px solid ${theme.palette.grey[100]}`,
  n300: `1px solid ${theme.palette.grey[300]}`,
  n500: `1px solid ${theme.palette.grey[500]}`,
  n700: `1px solid ${theme.palette.grey[700]}`,
  selected: `2px solid ${theme.palette.primary.main}`,
  active: `4px solid ${theme.palette.grey[700]}`,
  error: `1px solid ${theme.palette.error.main}`,
})

declare module '@mui/material/styles' {
  interface Theme {
    borders: {
      success: string
      primary: string
      n100: string
      n300: string
      n500: string
      n700: string
      selected: string
      active: string
      error: string
    }
  }

  interface ThemeOptions {
    borders?: {
      success?: string
      primary?: string
      n100?: string
      n300?: string
      n500?: string
      n700?: string
      selected?: string
      active?: string
      error?: string
    }
  }
}
