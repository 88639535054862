import { useState } from 'react'
import { useSearchParams, Form, useSubmit } from 'react-router-dom'
import { Stack, ToggleButton, Typography } from '@mui/material'
import { ToggleButtonGroup } from '../../components'
import { TrendCard } from './TrendCard'
import { Leaderboard } from './Leaderboard'
import { Breakdown } from './BreakdownContainer'

interface DealerRankProps {
  dealerStats: TrinityAPI.DealerRankType
  leaderboard: TrinityAPI.DealerLeaderboardType[]
  collections: TrinityAPI.DealerCollectionRankType[]
  garmentTypes: TrinityAPI.DealerGarmentTypesRankType[]
}

export function DealerRank({ dealerStats, leaderboard, collections, garmentTypes }: DealerRankProps) {
  const submit = useSubmit()

  return (
    <Stack spacing={8} component={Form} method='GET' onChange={e => submit(e.currentTarget)}>
      <Stack spacing={1}>
        <Typography variant='h4'>Dealer Rank</Typography>
        <Typography variant='body2'>Here's How You Stack Up Against Other Clothiers</Typography>
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={4}>
        <TrendCard amount={dealerStats.revenue} trend={dealerStats.revenueTrend} />
        <TrendCard units amount={dealerStats.units} trend={dealerStats.unitsTrend} />
      </Stack>
      <TimeframeToggle />
      <Leaderboard leaderboard={leaderboard} />
      <Breakdown isCollections data={collections} />
      <Breakdown isGarments data={garmentTypes} />
    </Stack>
  )
}

function TimeframeToggle() {
  const [params] = useSearchParams()
  const [timeframe, setTimeframe] = useState(params.get('timeframe') ?? 'mtd')

  return (
    <>
      <input type='hidden' name='timeframe' value={timeframe} />
      <ToggleButtonGroup
        size='large'
        value={timeframe}
        onChange={(_e, v) => v && setTimeframe(v)}
        sx={{ alignSelf: 'center', width: 'fit-content' }}
      >
        <ToggleButton type='submit' value='mtd'>
          Month To Date
        </ToggleButton>
        <ToggleButton type='submit' value='ytd'>
          Year To Date
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  )
}
