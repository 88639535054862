import { Typography } from '@mui/material'
import { formatMoney } from '@trinity/utils'
import { EmptyCard } from '../../components'

interface TrendCardProps {
  amount: number
  trend: number | null
  units?: boolean
  timeframe?: 'mtd' | 'ytd'
}

export function TrendCard({ amount, trend, units = false, timeframe = 'mtd' }: TrendCardProps) {
  const typeText = units ? 'Garments' : 'Spend'
  const timeframeText = timeframe === 'mtd' ? 'Month' : 'Year'
  const formattedAmount = units ? `${amount} Units` : formatMoney('USD', amount)

  return (
    <EmptyCard>
      <Typography>
        {typeText}: Current {timeframeText} To Date
      </Typography>
      <Typography variant='h2'>{formattedAmount}</Typography>
      <TrendText amount={trend ?? 0} />
    </EmptyCard>
  )
}

function TrendText({ amount }: { amount: number }) {
  const isPositive = amount > 0
  const arrow = isPositive ? '↑' : '↓'
  const color = isPositive ? 'success.main' : 'error.main'
  return (
    <Typography variant='smallBody3'>
      <Typography component='span' color={color} sx={{ fontWeight: 600 }}>
        {arrow} {Math.abs(amount)}%{' '}
      </Typography>
      vs. last month
    </Typography>
  )
}
