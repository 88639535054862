import { styled, ToggleButtonGroup as MuiToggleButtonGroup } from '@mui/material'
import type { ToggleButtonGroupProps } from '@mui/material'

export const ToggleButtonGroup = styled((props: ToggleButtonGroupProps) => (
  <MuiToggleButtonGroup exclusive size='small' {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  borderRadius: 50,
  '>:first-of-type': { borderRadius: 50 },
  '>:last-of-type': { borderRadius: 50 },
  '>*': {
    ...theme.typography.smallBody2,
    whiteSpace: 'nowrap',
    textTransform: 'capitalize !important',
    border: 'none !important',
    paddingLeft: `${theme.spacing(2)} !important`,
    paddingRight: `${theme.spacing(2)} !important`,
  },
  '>.Mui-selected': {
    color: `${theme.palette.primary.main} !important`,
    backgroundColor: `${theme.palette.common.white} !important`,
    borderRadius: '50px !important',
    boxShadow: theme.elevation.selectedButton,
  },
}))
