import { useEffect, useState } from 'react'
import { useFetcher } from 'react-router-dom'
import {
  Button,
  DialogContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { Dialog } from '../../components'

export function NotifyMe({ fabric }: { fabric: TrinityAPI.FabricFullType }) {
  const fetcher = useFetcher()
  const [open, setOpen] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const availableFactories = Object.keys(fabric.factories)
    .filter(factory => !fabric.factories[factory as keyof TrinityAPI.FactoryType]?.inStock)
    .reverse()

  useEffect(() => {
    if (fetcher.data === 201) {
      setOpen(false)
      setShowSuccess(true)
    }
  }, [fetcher])

  if (availableFactories.length === 0) {
    return null
  }

  return (
    <>
      <Typography variant='smallLink' sx={{ pt: 2 }} onClick={() => setOpen(true)}>
        Notify Me When Back in Stock
      </Typography>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <fetcher.Form method='post'>
            <Stack spacing={4} p={4} pt={2} width={{ mobile: 1, laptop: 400 }}>
              <Typography variant='body3' sx={{ lineHeight: 2, fontWeight: 600 }}>
                Register to receive a notification when fabric{' '}
                <Typography component='span' color='primary.main' sx={{ mr: 0.5, fontWeight: 700 }}>
                  {fabric.trinityFabricNumber}
                </Typography>
                comes back in stock.
              </Typography>
              <FormControl fullWidth error={Boolean(fetcher.data?.errors?.factory)}>
                <InputLabel>Select Factory</InputLabel>
                <Select name='factory' label='Select Factory' defaultValue={availableFactories[0]}>
                  {availableFactories.map(factory => (
                    <MenuItem key={factory} value={factory}>
                      {factory}
                    </MenuItem>
                  ))}
                  <MenuItem value='Both'>Both</MenuItem>
                </Select>
                {fetcher.data?.errors?.factory && <FormHelperText>{fetcher.data?.errors?.factory}</FormHelperText>}
              </FormControl>
              <TextField
                required
                name='email'
                type='email'
                label='Email'
                error={Boolean(fetcher.data?.errors?.email)}
                helperText={fetcher.data?.errors?.email}
              />
              <TextField multiline rows={4} name='dealerNote' type='text' label='Note' />
              <input type='hidden' name='fabricId' value={fabric.id} />
              <Button type='submit' disabled={fetcher.state !== 'idle'}>
                Notify Me
              </Button>
            </Stack>
          </fetcher.Form>
        </DialogContent>
      </Dialog>
      <Dialog open={showSuccess} onClose={() => setShowSuccess(false)}>
        <DialogContent>
          <Stack alignItems='center' spacing={4} p={4} pt={2} width={{ mobile: 1, laptop: 400 }}>
            <Typography variant='h4'>You're All Set!</Typography>
            <Typography variant='smallBody3'>We will let you know when it is back in stock.</Typography>
            <Button color='neutral' onClick={() => setShowSuccess(false)} sx={{ width: 1 }}>
              Close
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}
