import { type Components, type Theme } from '@mui/material'

//* Adding readOnly as a size variant to TextField since I cannot add it as a variant
//* or just a readonly prop (MUI doesn't provide a way to add overrides in the theme).
//* The commented classnames give all the styles needed to make the TextField look disabled.
export const TextField: Components<Omit<Theme, 'components'>>['MuiTextField'] = {
  variants: [
    {
      props: {
        size: 'readOnly',
        // className: 'Mui-disabled',
        // InputProps: { className: 'Mui-disabled' },
        // InputLabelProps: { className: 'Mui-disabled' },
        // FormHelperTextProps: { className: 'Mui-disabled' },
        // inputProps: { readOnly: true },
      },
      style: {
        pointerEvents: 'none', // Disable pointer events
        opacity: 0.4, // Reduce opacity to indicate read-only
      },
    },
  ],
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsSizeOverrides {
    readOnly: true
  }
}
