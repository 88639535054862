import { Checkbox as MuiCheckbox, FormControlLabel, Typography, Box, type FormControlLabelProps } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

interface CheckboxProps extends Omit<FormControlLabelProps, 'label' | 'name' | 'value' | 'control'> {
  title?: string
  filter?: {
    filterParam: string
    value: string
    title: string
  }
  checked?: boolean
}

export function Checkbox({ title = '', filter = undefined, checked = undefined, ...props }: CheckboxProps) {
  const [params] = useSearchParams()

  return (
    <FormControlLabel
      {...props}
      label={
        <Typography noWrap variant='smallBody1' sx={{ textTransform: 'capitalize' }}>
          {filter?.title ?? title}
        </Typography>
      }
      name={filter?.filterParam}
      value={filter?.value}
      control={
        <MuiCheckbox
          id={`${filter?.filterParam}-${filter?.value}`}
          icon={<CheckboxIcon />}
          checkedIcon={<CheckboxIcon isChecked />}
          checked={filter ? params.getAll(filter.filterParam).includes(filter.value) : checked}
        />
      }
    />
  )
}

const CheckboxIcon = ({ isChecked = false }: { isChecked?: boolean }) => (
  <Box
    sx={{ height: 17, width: 17, mt: -0.25, border: 'n300', bgcolor: isChecked ? 'primary.main' : 'common.white' }}
  />
)
