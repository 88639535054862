import { createContext, useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import { GarmentStatus } from '@trinity/utils'
import { useGlobalState } from '../../hooks'

const OrderStatusContext = createContext<OrderStatusContextValue | null>(null)
OrderStatusContext.displayName = 'OrderStatusContext'

export function OrderStatusProvider({ children }: OrderStatusContextProps) {
  const { onMobile, onSkiffle } = useGlobalState()
  const [params, setParams] = useSearchParams()
  const currentScope = params.get(SCOPE) ?? ALL
  const setScope = (scope: string) =>
    setParams(
      params => {
        params.set('scope', scope)
        params.set('page', '1')
        return params
      },
      { replace: true },
    )

  const value = {
    onMobile,
    onSkiffle,
    ALL,
    INCOMPLETE,
    DELAYED,
    EDITABLE,
    DELIVERY,
    OPEN,
    COMPLETED,
    ASCENDING,
    DESCENDING,
    isFilterScope,
    isTabScope,
    getOrderStatus,
    currentScope,
    setScope,
  }

  return <OrderStatusContext.Provider value={value}>{children}</OrderStatusContext.Provider>
}

export function useOrderStatus() {
  const context = useContext(OrderStatusContext)

  if (!context) {
    throw new Error('useOrderStatus must be used within an OrderStatusProvider')
  }

  return context
}

//* CONSTANTS
// Orders API Scopes
const ALL = 'all' as const
const INCOMPLETE = 'saved' as const
const DELAYED = 'delayed' as const

// Order Status Types
const EDITABLE = 'Editable' as const
const DELIVERY = 'Delivery' as const

// Filters
const OPEN = 'open' as const
const COMPLETED = 'completed' as const
const ASCENDING = 'asc' as const
const DESCENDING = 'desc' as const

// Helpers
const filterScopes = [ALL, OPEN, COMPLETED] as const
const tabScopes = [ALL, INCOMPLETE, DELAYED] as const
const isFilterScope = (scope: string) => filterScopes.some(s => s === scope)
const isTabScope = (scope: string | null) => tabScopes.some(s => s === scope)

// Mist
const SCOPE = 'scope' as const

//* TYPES
export interface OrderStatusContextProps {
  children: React.ReactNode
  // onSkiffle?: boolean
  // onMobile?: boolean
}
interface OrderStatusContextValue {
  onSkiffle: boolean
  onMobile: boolean
  ALL: typeof ALL
  INCOMPLETE: typeof INCOMPLETE
  DELAYED: typeof DELAYED
  EDITABLE: typeof EDITABLE
  DELIVERY: typeof DELIVERY
  OPEN: typeof OPEN
  COMPLETED: typeof COMPLETED
  ASCENDING: typeof ASCENDING
  DESCENDING: typeof DESCENDING
  isFilterScope: typeof isFilterScope
  isTabScope: typeof isTabScope
  getOrderStatus: typeof getOrderStatus
  currentScope: string
  setScope: (scope: string) => void
}

export function getOrderStatus(scope: string, garment: TrinityAPI.OrderHomeGarmentType) {
  if (!isFilterScope(scope)) {
    return null
  }

  if (garment.delayStatus.code !== 'OK') {
    return DELAYED
  } else if (GarmentStatus.EDITABLE.some(s => s === garment.orderStatus.description)) {
    return EDITABLE
  } else if (GarmentStatus.DELIVERABLE.some(s => s === garment.orderStatus.description)) {
    return DELIVERY
  } else {
    return null
  }
}
