import { Box } from '@mui/material'

interface CreditCardTextProps {
  creditCard?: TrinityAPI.CreditCardType | TrinityAPI.ChargeType | null
  children?: React.ReactNode
}

export function CreditCardText({ creditCard = null, children = null }: CreditCardTextProps) {
  if (!creditCard) return 'No Card Selected'

  return (
    <>
      {children}
      <Box component='span' sx={{ textTransform: 'capitalize' }}>
        {creditCard.cardType}
      </Box>{' '}
      ending in <strong>{creditCard.last4}</strong>
    </>
  )
}
