export const breakpoints = {
  // keys: ['mobile', 'tablet', 'laptop', 'desktop'],
  values: {
    xs: 0, // mobile
    sm: 640, // tablet
    md: 1024, // laptop
    lg: 1280, // desktop
    xl: 1920,
    mobile: 0,
    tablet: 640,
    laptop: 1024,
    desktop: 1280,
  },
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true // removes the `xs` breakpoint
    sm: true
    md: true
    lg: true
    xl: true
    mobile: true // adds the `mobile` breakpoint
    tablet: true
    laptop: true
    desktop: true
  }
}
