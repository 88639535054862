import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Card, CardMedia, Stack, Typography } from '@mui/material'

export function CollectionCard({ collection }: { collection: TrinityAPI.CollectionType }) {
  return (
    <motion.div whileHover={{ scale: 1.1 }} transition={{ duration: 0.25 }}>
      <Link to={String(collection.id)} style={{ textDecoration: 'none' }}>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: 141,
            width: { mobile: 320, tablet: 380 },
            pl: 2,
            pt: 3,
            boxShadow: theme => theme.elevation.card2,
          }}
        >
          <CardMedia image={collection.image} sx={{ height: 90, width: 90, mr: 2 }} />
          <Stack>
            <Typography gutterBottom variant='h6' sx={{ maxWidth: { mobile: 190, laptop: 250 } }}>
              {collection.title}
            </Typography>
            <Typography variant='subtitle1'>{collection.datecode}</Typography>
          </Stack>
        </Card>
      </Link>
    </motion.div>
  )
}
