import { Dialog as MuiDialog, DialogTitle, IconButton, type DialogProps } from '@mui/material'
import { Close } from '@mui/icons-material'

export function Dialog({ children, onClose = () => null, ...props }: DialogProps) {
  return (
    <MuiDialog onClose={onClose} {...props}>
      <DialogTitle>
        <IconButton
          aria-label='close'
          onClick={e => onClose(e, 'escapeKeyDown')}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey.900',
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      {children}
    </MuiDialog>
  )
}
