import { useEffect, useState } from 'react'
import { useFetcher } from 'react-router-dom'
import { IconButton } from '@mui/material'
import { Star, StarBorder } from '@mui/icons-material'

interface FabricFavoriteProps {
  fabric: TrinityAPI.FabricExtendedType
  isLarge?: boolean
}

export function FabricFavorite({ fabric, isLarge = false }: FabricFavoriteProps) {
  const [isFavorite, setIsFavorite] = useState(Boolean(fabric.favoriteId))
  const fetcher = useFetcher()
  const Icon = isFavorite ? Star : StarBorder

  useEffect(() => {
    setIsFavorite(Boolean(fabric.favoriteId))
  }, [fabric.favoriteId])

  function toggleFavorite(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    event.stopPropagation()
    const method = isFavorite ? 'delete' : 'create'
    const id = isFavorite && fabric.favoriteId ? fabric.favoriteId : fabric.id
    fetcher.submit(
      { id, method },
      { method: 'post', action: `/fabric-explorer/collections/${fabric.collectionId}?index` },
    )
    setIsFavorite(favorite => !favorite)
  }

  return (
    <IconButton size='small' type='submit' onClick={toggleFavorite}>
      <Icon sx={{ fontSize: isLarge ? 28 : 20, color: isFavorite ? 'warning.main' : 'grey.300' }} />
    </IconButton>
  )
}
