import { useContext } from 'react'
import { GlobalContext } from '../components/TrinityWrapper/GlobalProvider'

export function useGlobalState() {
  const globalContext = useContext(GlobalContext)

  if (globalContext === null) {
    throw new Error('useGlobalState must be used within a GlobalProvider')
  }

  return globalContext
}
