import { useState, useCallback, useEffect } from 'react'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { getBulletin } from '@trinity/utils'
import { Dialog } from '../Dialog'

export function Bulletins({ bulletins }: { bulletins: TrinityAPI.BulletinType[] }) {
  const [bulletinStep, setBulletinStep] = useState(0)
  const [fullBulletin, setFullBulletin] = useState<TrinityAPI.BulletinExtendedType | null>(null)
  const [openBulletin, setOpenBulletin] = useState(false)
  const incrementStep = useCallback(() => setBulletinStep(bulletinStep => bulletinStep + 1), [])
  const decrementStep = useCallback(() => setBulletinStep(bulletinStep => bulletinStep - 1), [])
  const closeBulletin = useCallback(() => setOpenBulletin(false), [])
  const bulletin = bulletins[bulletinStep]
  const minStep = bulletinStep > 0
  const maxStep = bulletinStep < bulletins.length - 1

  useEffect(() => {
    async function fetchBulletin() {
      if (bulletin) {
        const fullBulletin = await getBulletin(bulletin.id)
        setFullBulletin(fullBulletin)
      }
    }

    fetchBulletin()
  }, [bulletin])

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ width: 700 }}>
      <IconButton size='small' onClick={decrementStep}>
        {minStep && <KeyboardArrowLeft fontSize='large' />}
      </IconButton>
      <Box sx={{ px: 1, width: '80%' }}>
        <Typography gutterBottom textAlign='center' component='h6' variant='link' onClick={() => setOpenBulletin(true)}>
          {bulletin?.title}
        </Typography>
        <Typography noWrap textAlign='center' component='p' variant='smallBody1'>
          {bulletin?.detail}
        </Typography>
      </Box>
      <IconButton size='small' onClick={incrementStep}>
        {maxStep && <KeyboardArrowRight fontSize='large' />}
      </IconButton>
      <Dialog
        open={openBulletin}
        onClose={closeBulletin}
        PaperProps={{ sx: { maxWidth: 'fit-content', alignItems: 'flex-end' } }}
      >
        {fullBulletin ? (
          <div dangerouslySetInnerHTML={{ __html: fullBulletin.description }} />
        ) : (
          <h1>We're sorry, something went wrong. Please refresh the page and try again</h1>
        )}
      </Dialog>
    </Stack>
  )
}
