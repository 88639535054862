import { BottomNavigation, BottomNavigationAction, Chip, Stack, ToggleButton, Typography } from '@mui/material'
import { Assignment, TurnedInNot, Warning } from '@mui/icons-material'
import { Tab, Tabs, ToggleButtonGroup } from '../../components'
import { useOrderStatus } from './OrderStatusProvider'

export function OrderStatusTabs({ delays = 0 }: { delays?: number }) {
  const { ALL, DELAYED, INCOMPLETE, isTabScope, onSkiffle, onMobile, currentScope, setScope } = useOrderStatus()
  const scope = isTabScope(currentScope) ? currentScope : ALL

  const handleChange = (_event: React.SyntheticEvent, newScope: string) => {
    if (newScope) {
      setScope(newScope)
    }
  }

  if (onSkiffle) {
    return (
      <ToggleButtonGroup value={scope} onChange={handleChange}>
        <ToggleButton value={ALL}>Orders</ToggleButton>
        <ToggleButton value={DELAYED}>{DELAYED}</ToggleButton>
      </ToggleButtonGroup>
    )
  }

  if (onMobile) {
    return (
      <BottomNavigation
        value={scope}
        onChange={handleChange}
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          height: 72,
          borderTop: 'n100',
          zIndex: 'modal',
          '& .MuiBottomNavigationAction-label': { opacity: 1 },
          '& .MuiBottomNavigationAction-root.Mui-selected': { color: 'common.white', bgcolor: 'primary.main' },
        }}
      >
        <BottomNavigationAction
          label={ALL.toUpperCase()}
          value={ALL}
          icon={<Assignment fontSize='small' />}
          sx={{ typography: 'tab', '& .MuiBottomNavigationAction-label': { pt: 1 } }}
        />
        <BottomNavigationAction
          label={INCOMPLETE.toUpperCase()}
          value={INCOMPLETE}
          icon={<TurnedInNot fontSize='small' />}
          sx={{
            borderLeft: 'n100',
            borderRight: 'n100',
            typography: 'tab',
            '& .MuiBottomNavigationAction-label': { pt: 1 },
          }}
        />
        <BottomNavigationAction
          label={DELAYED.toUpperCase()}
          value={DELAYED}
          icon={<Warning fontSize='small' />}
          sx={{ typography: 'tab', '& .MuiBottomNavigationAction-label': { pt: 1 } }}
        />
      </BottomNavigation>
    )
  }

  return (
    <Tabs value={scope} onChange={handleChange} aria-label='dealer-home orders'>
      <Tab label='Orders' value={ALL} icon={<Assignment fontSize='small' />} />
      <Tab
        label={INCOMPLETE}
        value={INCOMPLETE}
        icon={<TurnedInNot fontSize='small' />}
        sx={{ borderLeft: 'n100', borderRight: 'n100' }}
      />
      <Tab label={<DelayedChip delays={delays} />} value={DELAYED} icon={<Warning fontSize='small' />} />
    </Tabs>
  )
}

function DelayedChip({ delays }: { delays: number }) {
  return (
    <Stack direction='row' spacing={1}>
      <Typography variant='tab' color='inherit'>
        Delays
      </Typography>
      <Chip
        color='error'
        label={delays}
        sx={{
          height: 20,
          width: 24,
          mt: '-1px !important',
          '& .MuiChip-label': { p: 0 },
        }}
      />
    </Stack>
  )
}
