// eslint-disable-next-line import/extensions
import 'groupby-polyfill/lib/polyfill.js'
import { useSearchParams } from 'react-router-dom'
import { subYears } from 'date-fns'
import { Divider, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { ALL_GARMENTS, formatDateAPI } from '@trinity/utils'
import { ClientAvatar, GarmentCards } from '../../components'

interface CloudClosetProps {
  client: TrinityAPI.ClientExtendedType
  garmentTypes: TrinityAPI.GarmentTypeType[]
  garments: Record<string, TrinityAPI.GarmentRenderType[]>
  children?: React.ReactNode
}

export function CloudCloset({ client, garmentTypes, garments, children = null }: CloudClosetProps) {
  const availableGarmentTypes = Object.keys(garments)

  return (
    <Stack spacing={6}>
      <ClientAvatar client={client} />
      <Divider />
      {children}
      <CloudClosetFilters garmentTypes={garmentTypes} />
      {availableGarmentTypes.length < 1 ? (
        <Typography variant='h5' align='center'>
          No Garments Found
        </Typography>
      ) : (
        availableGarmentTypes.map(garmentType => (
          <GarmentCards key={garmentType} garments={garments[garmentType] ?? []} />
        ))
      )}
    </Stack>
  )
}

function CloudClosetFilters({ garmentTypes }: Pick<CloudClosetProps, 'garmentTypes'>) {
  const [params, setParams] = useSearchParams()
  const garmentType = params.get('garmentType') ?? 'all'
  const endDate = params.get('endDate') ? new Date(params.get('endDate') as string) : new Date()
  const startDate = params.get('startDate') ? new Date(params.get('startDate') as string) : subYears(endDate, 3)

  return (
    <Stack direction={{ mobile: 'column', laptop: 'row' }} spacing={4} justifyContent='space-between'>
      <TextField
        select
        label='Garment Type'
        value={garmentType}
        onChange={e =>
          setParams(params => {
            params.set('garmentType', e.target.value)
            return params
          })
        }
        sx={{ width: { mobile: 1, laptop: 1 / 5 } }}
      >
        <MenuItem value='all'>All</MenuItem>
        {ALL_GARMENTS.map(garmentType => (
          <MenuItem
            key={garmentType.bitmask}
            disabled={!garmentTypes.includes(garmentType.bitmask)}
            value={garmentType.bitmask}
          >
            {garmentType.alternateName ?? garmentType.formalName}
          </MenuItem>
        ))}
      </TextField>
      <Stack direction='row' spacing={2}>
        <DatePicker
          label='From'
          defaultValue={startDate}
          onChange={newDate =>
            setParams(params => {
              if (newDate) {
                params.set('startDate', formatDateAPI(newDate))
              }
              return params
            })
          }
          slotProps={{
            textField: { helperText: 'MM/DD/YYYY', name: 'startDate' },
            openPickerIcon: { sx: { color: 'grey.900' } },
          }}
        />
        <DatePicker
          label='To'
          defaultValue={endDate}
          onChange={newDate =>
            setParams(params => {
              if (newDate) {
                params.set('endDate', formatDateAPI(newDate))
              }
              return params
            })
          }
          slotProps={{
            textField: { helperText: 'MM/DD/YYYY', name: 'endDate' },
            openPickerIcon: { sx: { color: 'grey.900' } },
          }}
        />
      </Stack>
    </Stack>
  )
}
