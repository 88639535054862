import { useEffect, useRef, useState } from 'react'
import QrScan from 'qr-scanner'
import { Typography } from '@mui/material'

export function QrScanner({ handleScanResult }: { handleScanResult: (result: string) => void }) {
  const videoEl = useRef<HTMLVideoElement>(null)
  const scanner = useRef<QrScan>()
  const [hasCamera, setHasCamera] = useState(false)

  async function checkForCamera() {
    try {
      const hasCamera = await QrScan.hasCamera()
      setHasCamera(hasCamera)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    void checkForCamera()
  }, [])

  useEffect(() => {
    if (videoEl.current && hasCamera) {
      scanner.current = new QrScan(videoEl.current, (result: QrScan.ScanResult) => handleScanResult(result.data), {
        onDecodeError: error => console.error(error),
        preferredCamera: 'environment',
        highlightScanRegion: true,
        highlightCodeOutline: true,
      })
      scanner.current.setInversionMode('both')
      void scanner.current.start()
    }

    return () => scanner.current?.destroy()
  }, [handleScanResult, hasCamera])

  if (hasCamera) {
    return <video ref={videoEl} style={{ width: '100%' }} />
  }

  return <Typography color='error'>Error: No Camera Found</Typography>
}
