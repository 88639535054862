import { useState } from 'react'
import { useFetcher } from 'react-router-dom'
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion'
import { Divider, Stack, Typography, Unstable_Grid2 as Grid, IconButton, Tooltip, useTheme, Zoom } from '@mui/material'
import { Fullscreen, Inventory, Restore } from '@mui/icons-material'
import { formatDate } from '@trinity/utils'
import { Dialog } from '../Dialog'

interface GarmentCardsProps {
  garments: TrinityAPI.GarmentRenderType[]
}

export function GarmentCards({ garments }: GarmentCardsProps) {
  if (garments.length < 1) return null

  const garmentType = garments[0]?.garmentType

  return (
    <Stack spacing={4}>
      <Typography variant='h4'>{garmentType?.alternateName ?? garmentType?.formalName}</Typography>
      <Grid container>
        <LayoutGroup>
          <AnimatePresence mode='popLayout'>
            {garments.map(garment => (
              <Grid key={garment.id}>
                <motion.div
                  layout
                  initial={{ scale: 0.5, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0.5, opacity: 0 }}
                  transition={{ type: 'tween' }}
                  key={garment.id}
                >
                  <GarmentCard garment={garment} />
                </motion.div>
              </Grid>
            ))}
          </AnimatePresence>
        </LayoutGroup>
      </Grid>
    </Stack>
  )
}

interface GarmentCardProps {
  garment: TrinityAPI.GarmentRenderType
}

export function GarmentCard({ garment }: GarmentCardProps) {
  const fetcher = useFetcher()
  const [isHidden, setIsHidden] = useState(true)
  const theme = useTheme()
  const [isExpanded, setIsExpanded] = useState(false)
  const [startPosition, setStartPosition] = useState('')

  return (
    <>
      <motion.div
        layout
        style={{
          boxShadow: isHidden ? 'none' : theme.elevation.card,
          opacity: isExpanded ? 0.25 : 1,
          scale: isExpanded ? 1.1 : 1,
        }}
        transition={{ duration: 0.25 }}
      >
        <Stack p={4} onMouseEnter={() => setIsHidden(false)} onMouseLeave={() => setIsHidden(true)}>
          {!isHidden && (
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Tooltip title='Details' placement='top'>
                <IconButton
                  onClick={e => {
                    setStartPosition(`${e.clientX}px ${e.clientY}px`)
                    setIsExpanded(true)
                  }}
                  onMouseEnter={() => (new Image().src = `${garment.uri}?width=1000`)}
                >
                  <Fullscreen />
                </IconButton>
              </Tooltip>
              <fetcher.Form method='POST'>
                <input type='hidden' name='isHidden' value={garment.isHidden ? '0' : '1'} />
                <input type='hidden' name='id' value={garment.id} />
                <Tooltip title={garment.isHidden ? 'Restore' : 'Archive'} placement='top'>
                  <IconButton type='submit' color={garment.isHidden ? 'primary' : 'error'}>
                    {garment.isHidden ? <Restore /> : <Inventory />}
                  </IconButton>
                </Tooltip>
              </fetcher.Form>
            </Stack>
          )}
          <img
            src={`${garment.uri ?? garment.garmentType.fallbackImage}&width=500`}
            alt={garment.fabricDescription}
            height={375}
            //? We need to set the width to 375 for suits to prevent the image from being stretched due to the aspect ratio
            width={garment.garmentType.formalName === 'Suit' ? 375 : 250}
          />
          {!isHidden && <Divider />}
          {!isHidden && (
            <Stack direction='row' alignItems='center' justifyContent='space-between' pt={2}>
              <Typography variant='h5'>{garment.title}</Typography>
              <Typography variant='smallBody2' align='right'>
                {formatDate(garment.createdAt)}
              </Typography>
            </Stack>
          )}
          {!isHidden && (
            <Stack>
              <Typography variant='body2'>
                <Typography variant='body3' component='span' sx={{ fontWeight: 600 }}>
                  Fabric:
                </Typography>{' '}
                {garment.trinityFabricNumber}
              </Typography>
              {garment.lining && (
                <Typography variant='body2'>
                  <Typography variant='body3' component='span' sx={{ fontWeight: 600 }}>
                    Lining:
                  </Typography>{' '}
                  {garment.lining}
                </Typography>
              )}
              {garment.button && (
                <Typography noWrap variant='body2' sx={{ maxWidth: 250 }}>
                  <Typography variant='body3' component='span' sx={{ fontWeight: 600 }}>
                    Button:
                  </Typography>{' '}
                  {garment.button}
                </Typography>
              )}
            </Stack>
          )}
        </Stack>
      </motion.div>
      <GarmentCardDialog
        garment={garment}
        isOpen={isExpanded}
        close={() => setIsExpanded(false)}
        transformOrigin={startPosition}
      />
    </>
  )
}

interface GarmentCardDialogProps {
  garment: TrinityAPI.GarmentRenderType
  isOpen: boolean
  close: () => void
  transformOrigin: string
}

function GarmentCardDialog({ garment, isOpen, close, transformOrigin }: GarmentCardDialogProps) {
  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={close}
      TransitionComponent={Zoom}
      transitionDuration={750}
      TransitionProps={{
        style: { transformOrigin },
      }}
      PaperProps={{ sx: { maxWidth: 1050 } }}
    >
      <Stack p={4} alignItems='center'>
        <Stack spacing={1} alignItems='center'>
          <Typography variant='h3'>{garment.title}</Typography>
          <Typography variant='body2'>Order Date: {formatDate(garment.createdAt)}</Typography>
        </Stack>
        <img
          src={`${garment.uri ?? garment.garmentType.fallbackImage}&width=1000`}
          height={1000}
          width={garment.garmentType.formalName === 'Suit' ? 1000 : 700}
          alt={garment.fabricDescription}
        />
        <Divider />
        <Stack pt={1} spacing={1} alignSelf='flex-start'>
          <Typography variant='body3'>
            <strong>Fabric: </strong> {garment.trinityFabricNumber}
          </Typography>
          <Typography variant='body3'>
            <strong>Description: </strong>
            {garment.fabricDescription}
          </Typography>
          <Typography variant='body3'>
            <strong>Collection: </strong>
            {garment.collection}
          </Typography>
          {garment.lining && (
            <Typography variant='body3'>
              <strong>Lining: </strong>
              {garment.lining}
            </Typography>
          )}
          {garment.button && (
            <Typography variant='body3'>
              <strong>Button: </strong>
              {garment.button}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Dialog>
  )
}
