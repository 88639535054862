import { useSearchParams } from 'react-router-dom'
import { MenuItem, Pagination as MuiPagination, Select, Stack, Typography } from '@mui/material'
import { type SelectChangeEvent, type StackProps } from '@mui/material'
import { type NonEmptyArray } from '@trinity/utils'

interface PaginationProps extends StackProps {
  total: number
  pageSizeOptions: NonEmptyArray<number>
}

export function Pagination({ total, pageSizeOptions, ...props }: PaginationProps) {
  const [params, setParams] = useSearchParams()
  const perPage = params.get('perPage') ? Number(params.get('perPage')) : pageSizeOptions[0]
  const page = params.get('page') ? Number(params.get('page')) : 1
  const totalPages = Math.ceil(total / perPage)

  function handlePageChange(_event: React.ChangeEvent<unknown>, value: number) {
    setParams(
      params => {
        params.set('page', String(value))
        return params
      },
      { replace: true },
    )
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  function handlePerPageChange(event: SelectChangeEvent) {
    setParams(
      params => {
        params.set('perPage', event.target.value)
        return params
      },
      { replace: true },
    )
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  if (totalPages < 2) {
    return null
  }

  return (
    <Stack direction='row' spacing={2} alignSelf='center' alignItems='center' {...props}>
      <MuiPagination
        size='small'
        color='primary'
        shape='rounded'
        page={page}
        count={totalPages}
        onChange={handlePageChange}
      />
      <Select
        id='select page size'
        autoWidth
        value={String(perPage)}
        sx={{ display: { mobile: 'none', laptop: 'inherit' }, height: 30 }}
        onChange={handlePerPageChange}
      >
        {pageSizeOptions.map(size => (
          <MenuItem key={size} value={size}>
            <Typography variant='smallBody1'>{`${size} / page`}</Typography>
          </MenuItem>
        ))}
      </Select>
    </Stack>
  )
}
