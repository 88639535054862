import { createContext, useMemo, useState } from 'react'
import { Alert, Snackbar, useMediaQuery, useTheme } from '@mui/material'

export interface GlobalContextValue {
  setToastInfo: React.Dispatch<React.SetStateAction<ToastInfoType>>
  onMobile: boolean
  onTablet: boolean
  onLaptop: boolean
  onDesktop: boolean
  onSkiffle: boolean
  user: TrinityAPI.LoginType
}

interface GlobalProviderProps {
  user: TrinityAPI.LoginType
  children: React.ReactNode
}

const GlobalContext = createContext<GlobalContextValue | null>(null)
GlobalContext.displayName = 'GlobalContext'

function GlobalProvider({ user, children }: GlobalProviderProps) {
  const [toastInfo, setToastInfo] = useState<ToastInfoType>(defaultToastInfo)
  const theme = useTheme()
  const onMobile = useMediaQuery(theme.breakpoints.between('mobile', 'tablet'))
  const onTablet = useMediaQuery(theme.breakpoints.only('tablet'))
  const onLaptop = useMediaQuery(theme.breakpoints.only('laptop'))
  const onDesktop = useMediaQuery(theme.breakpoints.up('desktop'))
  const onSkiffle = window.location.hostname.startsWith('skiffle') || window.location.host.startsWith('localhost:1236')

  const value = useMemo(
    () => ({ onMobile, onTablet, onLaptop, onDesktop, onSkiffle, user, setToastInfo }),
    [onDesktop, onLaptop, onMobile, onSkiffle, onTablet, user],
  )

  return (
    <GlobalContext.Provider value={value}>
      <Snackbar
        open={toastInfo.show && toastInfo.message !== ''}
        autoHideDuration={6000}
        onClose={() => setToastInfo(defaultToastInfo)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setToastInfo(defaultToastInfo)} severity={toastInfo.severity} sx={{ mt: 10 }}>
          {toastInfo.message}
        </Alert>
      </Snackbar>
      {children}
    </GlobalContext.Provider>
  )
}

export { GlobalProvider, GlobalContext }

const defaultToastInfo: ToastInfoType = { show: false, message: '', severity: 'success' }
interface ToastInfoType {
  show: boolean
  message: string
  severity: 'success' | 'info' | 'warning' | 'error'
}
