import { type CSSInterpolation, type Theme } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

export const Accordion = {
  defaultProps: {
    disableGutters: true,
    TransitionProps: { unmountOnExit: true },
  },
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      boxShadow: theme.shadows[0],
      '&:before': { display: 'none' },
      '& .MuiAccordionSummary-root.Mui-expanded': {
        border: theme.borders.selected,
        boxShadow: theme.elevation.accordian,
      },
    }),
  },
}

export const AccordionSummary = {
  defaultProps: {
    expandIcon: <ExpandMore />,
  },
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) =>
      ({
        flexDirection: 'row-reverse',
        height: 76,
        border: theme.borders.n100,
      }) as CSSInterpolation,
    content: {
      alignItems: 'center',
    },
  },
}
