import { NoOrdersFound } from './NoOrdersFound'
import { OrderStatusAccordion } from './OrderStatusAccordion'
import { OrderStatusFilters } from './OrderStatusFilters'
import { OrderStatusProvider, type OrderStatusContextProps } from './OrderStatusProvider'
import { OrderStatusTabs } from './OrderStatusTabs'

OrderStatus.Tabs = OrderStatusTabs
OrderStatus.Filters = OrderStatusFilters
OrderStatus.Accordion = OrderStatusAccordion
OrderStatus.NoOrdersFound = NoOrdersFound

export function OrderStatus(props: OrderStatusContextProps) {
  return <OrderStatusProvider {...props} />
}
