import { cloneElement, useEffect, useRef, useState } from 'react'
import { useFetcher, useSearchParams } from 'react-router-dom'
import {
  ClickAwayListener,
  FormControl,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  OutlinedInput,
  type OutlinedInputProps,
  Paper,
  Popper,
  Typography,
  DialogTitle,
  DialogContent,
} from '@mui/material'
import { Close, Search as MuiSearch, QrCodeScanner } from '@mui/icons-material'
import { Dialog } from '../Dialog'
import { QrScanner } from '../QrScanner'

interface BaseTypes<DataType> extends OutlinedInputProps {
  isHome?: boolean
  method?: 'get' | 'post'
  initialValue?: string
  children?: ({ suggestion, searchTerm }: { suggestion: DataType; searchTerm: string }) => JSX.Element
  handleSubmit?: (searchTerm: string) => void
  handleClear?: () => void
  offset?: number
}

type SearchProps<DataType> = BaseTypes<DataType> &
  ({ withQrScanner: true; qrFunction: (v: string) => void } | { withQrScanner?: false; qrFunction?: never })

export function Search<DataType extends object>({
  placeholder = 'Search',
  isHome = false,
  withQrScanner = false,
  qrFunction = () => null,
  method = 'post',
  initialValue = '',
  handleSubmit = () => null,
  handleClear = () => null,
  children = undefined,
  offset = 0,
  ...props
}: SearchProps<DataType>) {
  const [value, setValue] = useState(initialValue)
  const [open, setOpen] = useState(false)
  const [qrOpen, setQrOpen] = useState(false)
  const ref = useRef<HTMLInputElement | null>(null)
  const [params, setParams] = useSearchParams()
  const fetcher = useFetcher()

  useEffect(() => {
    if (method === 'get') {
      setParams(params => {
        params.set('q', value)
        return params
      })
    }
  }, [method, setParams, value])

  useEffect(() => {
    setValue(initialValue)
    setOpen(false)
  }, [initialValue])

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <FormControl
        component={fetcher.Form}
        method={method}
        sx={{ width: 1, alignItems: 'center' }}
        onChange={event => fetcher.submit(event.currentTarget)}
        onSubmit={e => {
          e.preventDefault()
          handleSubmit(value)
          setOpen(false)
          e.currentTarget.querySelector('input')?.blur()
        }}
        onFocus={e => {
          fetcher.submit(e.currentTarget)
        }}
      >
        <OutlinedInput
          {...props}
          ref={ref}
          placeholder={placeholder}
          value={value}
          onChange={e => setValue(e.currentTarget.value)}
          onFocus={() => setOpen(true)}
          sx={{
            height: isHome ? { mobile: 68, laptop: 60 } : { mobile: 52, laptop: 43 },
            width: 1,
            borderRadius: isHome ? 47 : 'default',
            boxShadow: theme => (isHome ? theme.elevation.search : 'none'),
            '& .MuiOutlinedInput-input': {
              typography: 'smallBody1',
              ml: 1,
              color: 'common.black',
            },
          }}
          startAdornment={
            withQrScanner ? (
              <InputAdornment position='start' sx={{ display: { laptop: 'none' } }}>
                <IconButton size='small' onClick={() => setQrOpen(true)}>
                  <QrCodeScanner color='primary' />
                </IconButton>
              </InputAdornment>
            ) : (
              props.startAdornment
            )
          }
          endAdornment={
            <InputAdornment position='end' disablePointerEvents={!value}>
              <IconButton
                size='small'
                onClick={() => {
                  if (params.has('q')) {
                    setParams(
                      params => {
                        params.delete('q')
                        return params
                      },
                      { replace: true },
                    )
                  }
                  setValue('')
                  handleClear()
                }}
              >
                {value ? <Close color='disabled' /> : <MuiSearch color='primary' />}
              </IconButton>
            </InputAdornment>
          }
        />
        {withQrScanner && (
          <Dialog open={qrOpen} onClose={() => setQrOpen(false)}>
            <DialogTitle>Scan QR Code</DialogTitle>
            <DialogContent>
              <QrScanner handleScanResult={qrFunction} />
            </DialogContent>
          </Dialog>
        )}
        {children && (
          <Popper
            disablePortal
            open={open && Boolean(value)}
            anchorEl={ref.current}
            modifiers={[{ name: 'offset', enabled: true, options: { offset: [0, offset] } }]}
            sx={{ width: 1, zIndex: 'fab' }}
          >
            <Paper
              sx={{
                border: 'n100',
                boxShadow: theme => theme.elevation.autocomplete,
                p: 2,
                maxHeight: '32rem',
                overflowY: 'scroll',
                overflowX: 'clip',
              }}
            >
              <List>
                {fetcher?.data?.suggestions &&
                Array.isArray(fetcher.data.suggestions) &&
                fetcher.data.suggestions.length > 0 ? (
                  fetcher?.data?.suggestions?.map((suggestion: DataType, index: number) =>
                    cloneElement(children({ suggestion, searchTerm: value }), { key: index }),
                  )
                ) : (
                  <ListItem>
                    <Typography variant='body3'>No Results</Typography>
                  </ListItem>
                )}
              </List>
            </Paper>
          </Popper>
        )}
      </FormControl>
    </ClickAwayListener>
  )
}
