import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import { downloadClientCSV } from '@trinity/utils'
import { Pagination, Search } from '../../components'
import { useGlobalState } from '../../hooks'
import { ClientsTable } from './ClientsTable'

interface ClientsProps {
  clients: TrinityAPI.ClientDashboardType[]
  totalClients: number
}

export function Clients({ clients, totalClients }: ClientsProps) {
  const { onMobile } = useGlobalState()
  const [params] = useSearchParams()
  const searchParam = params.get('q') ?? ''

  return (
    <Stack spacing={{ mobile: 4, tablet: 2 }} pt={2}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='h2'>Clients</Typography>
        {onMobile && <ClientsButtons />}
      </Stack>
      <Typography variant='body2'>{totalClients} Clients</Typography>
      <Stack direction='row' justifyContent='space-between' pt={{ tablet: 2 }}>
        {!onMobile && <ClientsButtons />}
        <Box width={{ mobile: 1, tablet: 1 / 4 }}>
          <Search name='q' placeholder='Search Clients...' method='get' initialValue={searchParam} />
        </Box>
      </Stack>
      <ClientsTable clients={clients} />
      <Pagination total={totalClients} pageSizeOptions={[100, 200, 300]} />
    </Stack>
  )
}

function ClientsButtons() {
  const { onMobile } = useGlobalState()
  const { user } = useGlobalState()
  const navigate = useNavigate()

  if (onMobile) {
    return (
      <IconButton
        disableRipple
        onClick={() => navigate('new')}
        sx={{ backgroundColor: 'primary.main', color: 'common.white' }}
      >
        <Add fontSize='large' />
      </IconButton>
    )
  }

  return (
    <Stack direction='row' spacing={2}>
      <Button onClick={() => navigate('new')}>New Client</Button>
      <Button variant='outlined' href={downloadClientCSV(user.token)}>
        Export All
      </Button>
    </Stack>
  )
}
