import { Stack, Typography } from '@mui/material'
import { EmptyHanger } from '../../assets'
import { useOrderStatus } from './OrderStatusProvider'

export function NoOrdersFound() {
  const { currentScope, ALL } = useOrderStatus()
  const title = currentScope === ALL ? '' : currentScope

  return (
    <Stack alignItems='center' spacing={2} sx={{ pb: 10, pt: 4 }}>
      <img src={EmptyHanger} alt='No Orders Found' height={107} width={188} />
      <div>
        <Typography align='center' variant='h5' sx={{ textTransform: 'capitalize' }}>
          No {title} Orders
        </Typography>
        <Typography variant='body1' align='center'>
          Your {title} orders will show up here
        </Typography>
      </div>
    </Stack>
  )
}
