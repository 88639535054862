import '@mui/material'

export const palette = {
  background: {
    default: '#FFFFFF',
  },
  common: {
    white: '#FFFFFF',
    black: '#000000',
  },
  primary: {
    extraLight: '#EDECF9',
    light: '#746ED4',
    main: '#524ACA',
    dark: '#39338D',
    contrastText: '#FFFFFF',
  },
  secondary: {
    extraLight: '#FCE8EF',
    light: '#ED4B82',
    main: '#E91E63',
    dark: '#A31545',
    contrastText: '#FFFFFF',
  },
  error: {
    extraLight: '#F9EAEA',
    light: '#DE5F5F',
    main: '#D63737',
    dark: '#952626',
    contrastText: '#FFFFFF',
  },
  warning: {
    extraLight: '#FDF4E9',
    light: '#F3AA51',
    main: '#F09526',
    dark: '#A8681A',
    contrastText: '#FFFFFF',
  },
  success: {
    extraLight: '#EBF7F1',
    light: '#45BA7D',
    main: '#17A95D',
    dark: '#107641',
    contrastText: '#FFFFFF',
  },
  info: {
    extraLight: '#E9F4FD',
    light: '#4EA0E4',
    main: '#2289DE',
    dark: '#175F9B',
    contrastText: '#FFFFFF',
  },
  neutral: {
    extraLight: '#E1E2EB',
    light: '#CCCEDD',
    main: '#6D6D8C',
    dark: '#424254',
    contrastText: '#FFFFFF',
  },
  grey: {
    50: '#F1F2F6',
    100: '#CCCEDD',
    300: '#9B9EB8',
    500: '#6D6D8C',
    700: '#424254',
    900: '#32325B',
    A100: '#E1E2EB',
  },
}

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary']
  }

  interface PaletteOptions {
    neutral: PaletteOptions['primary']
  }

  interface PaletteColor {
    extraLight?: string
  }

  interface SimplePaletteColorOptions {
    extraLight?: string
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    neutral: true
  }
}
