import { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import Highlighter from 'react-highlight-words'
import {
  Button,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { FilterList } from '@mui/icons-material'
import { handleCollectionFolderQrScan } from '@trinity/utils'
import { CollectionCard, Pagination, Search } from '../../components'
import { useFilters } from '../../hooks'

interface FabricExplorerCollectionsProps {
  collections: TrinityAPI.CollectionType[]
  favoritesCollection: TrinityAPI.CollectionType
  totalCollections: number
  collectionFilters: TrinityAPI.CollectionFiltersType
}

export function FabricExplorerCollections({
  collections,
  totalCollections,
  favoritesCollection,
  collectionFilters,
}: FabricExplorerCollectionsProps) {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [filters, setFilters] = useState(baseFilters)
  const { Filters, FilterChips } = useFilters({ filters, open: drawerOpen, close: () => setDrawerOpen(false) })
  const [params, setParams] = useSearchParams()

  useEffect(() => {
    setFilters(oldFilters => ({
      ...oldFilters,
      brand: collectionFilters.brands.map(brand => ({
        title: brand,
        filterParam: 'brand',
        value: brand,
      })),
      year: collectionFilters.years.map(year => ({
        title: year,
        filterParam: 'year',
        value: year,
      })),
    }))
  }, [collectionFilters])

  useEffect(() => {
    const brandParams = params.getAll('brand')
    const yearParams = params.getAll('year')
    const brands = filters?.brand?.map(brand => brand.value)
    const years = filters?.year?.map(year => year.value)
    const brandsToRemove = brandParams.filter(brand => !brands?.includes(brand))
    const yearsToRemove = yearParams.filter(year => !years?.includes(year))
    if (brandsToRemove.length > 0 || yearsToRemove.length > 0) {
      setParams(params => {
        brandsToRemove.forEach(brand => {
          params.delete('brand', brand)
        })
        yearsToRemove.forEach(year => {
          params.delete('year', year)
        })
        return params
      })
    }
  }, [filters, params, setParams])

  return (
    <Stack direction='row' spacing={4}>
      <Filters />
      <Stack pt={{ mobile: 2, tablet: 8 }} px={{ mobile: 0, tablet: 4 }} width={1}>
        <Stack
          direction={{ mobile: 'column', laptop: 'row' }}
          alignItems={{ laptop: 'center' }}
          justifyContent='space-between'
          spacing={{ mobile: 4, laptop: 0 }}
          pb={{ mobile: 4, tablet: 0 }}
        >
          <Typography variant='h1'>Collections</Typography>
          <Stack direction={{ mobile: 'column', laptop: 'row' }} spacing={2}>
            <Button
              variant='outlined'
              component={Link}
              to='../fabrics'
              sx={{ width: 'fit-content', whiteSpace: 'nowrap' }}
            >
              Find a Fabric
            </Button>
            <Divider orientation='vertical' sx={{ height: 40, display: { mobile: 'none', laptop: 'block' } }} />
            <Stack direction='row' spacing={1} width={{ mobile: 1, laptop: 350 }}>
              <Search<TrinityAPI.CollectionType>
                withQrScanner
                qrFunction={handleCollectionFolderQrScan}
                name='search'
                placeholder='Search collections...'
              >
                {({ suggestion, searchTerm }) => (
                  <CollectionSuggestion suggestion={suggestion} searchTerm={searchTerm} />
                )}
              </Search>

              <IconButton
                color='primary'
                onClick={() => setDrawerOpen(true)}
                sx={{ border: 'primary', borderRadius: 1, px: 2.5, display: { laptop: 'none' } }}
              >
                <FilterList />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
        <Divider sx={{ pt: 4, mb: 4, display: { mobile: 'none', tablet: 'block' } }} />
        <FilterChips />
        {collections.length < 1 ? (
          <Typography textAlign='center' variant='h4'>
            No collections found
          </Typography>
        ) : (
          <Grid
            container
            alignItems='center'
            justifyContent={{ mobile: 'center', tablet: 'space-evenly' }}
            spacing={2}
            mt={4}
          >
            <Grid item>
              <CollectionCard collection={favoritesCollection} />
            </Grid>
            {collections.map(collection => (
              <Grid item key={collection.id}>
                <CollectionCard collection={collection} />
              </Grid>
            ))}
          </Grid>
        )}
        <Pagination total={totalCollections} pageSizeOptions={[23, 47, 95]} sx={{ pt: 5 }} />
      </Stack>
    </Stack>
  )
}

function CollectionSuggestion({
  suggestion,
  searchTerm,
}: {
  suggestion: TrinityAPI.CollectionType
  searchTerm: string
}) {
  return (
    <ListItem disablePadding>
      <ListItemButton component={Link} to={String(suggestion.id)}>
        <ListItemText primaryTypographyProps={{ variant: 'body3' }}>
          <Highlighter searchWords={[searchTerm]} textToHighlight={suggestion.name} highlightTag='strong' />
        </ListItemText>
      </ListItemButton>
    </ListItem>
  )
}

const baseFilters: TrinityAPI.FiltersType = {
  usage: [
    { title: 'clothing', filterParam: 'usage', value: 'clothing' },
    { title: 'shirting', filterParam: 'usage', value: 'shirting' },
    { title: 'clothing trim', filterParam: 'usage', value: 'clothing-trim' },
    { title: 'shirting trim', filterParam: 'usage', value: 'shirting-trim' },
  ],
  brand: [],
  year: [],
  include: [{ title: 'cross-coded', filterParam: 'crossCoded', value: 'true' }],
}
