import { useNavigate } from 'react-router-dom'
import { Divider, Grid, Stack, Typography } from '@mui/material'
import { CASUALJACKET, COAT, PANT, SHIRT, TOPCOAT, VEST } from '@trinity/utils'

export function GarmentList() {
  const navigate = useNavigate()

  return (
    <Grid
      container
      direction={{ mobile: 'column', laptop: 'row' }}
      alignItems='center'
      rowSpacing={{ mobile: 4, laptop: 10 }}
      columnSpacing={10}
    >
      {garmentTypes.map(type => (
        <Grid item key={type.abbreviation} mobile={1} laptop={6} width={1}>
          <Stack
            spacing={2}
            alignItems='center'
            onClick={() => navigate(String(type.bitmask))}
            sx={{
              boxShadow: theme => theme.elevation.card2,
              py: 2,
              border: '2px solid transparent',
              borderRadius: 2,
              ':hover': {
                backgroundColor: 'primary.extraLight',
                border: 'selected',
                cursor: 'pointer',
                '& p': {
                  fontWeight: 600,
                  color: 'primary.main',
                },
                '& hr': {
                  borderColor: 'primary.main',
                },
              },
            }}
          >
            <img src={type.image} alt={type.formalName} />
            <Divider sx={{ width: 1 }} />
            <Typography>{type.formalName}</Typography>
          </Stack>
        </Grid>
      ))}
    </Grid>
  )
}

const garmentTypes = [COAT, VEST, PANT, SHIRT, TOPCOAT, CASUALJACKET]
