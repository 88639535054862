import { useMemo } from 'react'
import { Form, useSearchParams, useSubmit } from 'react-router-dom'
import { Button, Divider, Drawer, Stack, Typography, Unstable_Grid2 as Grid, Chip } from '@mui/material'
import { Close } from '@mui/icons-material'
import { Checkbox } from '../components'
import { useGlobalState } from '.'

export function useFilters({ filters, open, close }: UseFiltersProps) {
  const Filters = useMemo(() => {
    const Filters = ({ ...childProps }: ChildProps) => (
      <BaseFilters filters={filters} open={open} close={close} {...childProps} />
    )
    return Filters
  }, [filters, open, close])

  return {
    FilterChips: () => <FilterChips filters={Object.values(filters).flat()} />,
    Filters,
  }
}

//* --------------- COMPONENTS ---------------
function BaseFilters({ open = false, close = () => null, ...props }: ChildProps & UseFiltersProps) {
  const { onMobile } = useGlobalState()

  if (onMobile) {
    return (
      <Drawer anchor='bottom' open={open} onClose={close} PaperProps={{ sx: { top: '15%', pl: 4 } }}>
        <FilterList close={close} {...props} />
      </Drawer>
    )
  }

  return (
    <>
      <FilterList {...props} />
      <Divider orientation='vertical' sx={{ height: 'auto' }} />
    </>
  )
}

function FilterList({ filters, close, children = null }: FilterListProps) {
  const submit = useSubmit()
  const [_, setParams] = useSearchParams()

  return (
    <Form method='get' onChange={e => submit(e.currentTarget)}>
      <Stack pt={4} pb={15} width={{ mobile: '90%', laptop: 'max-content' }} position='sticky' top={0}>
        <Stack direction='row' alignItems='center' justifyContent='space-between' mb={4}>
          <Button
            variant='text'
            color='neutral'
            sx={{ width: 'fit-content' }}
            onClick={() => {
              setParams(params => {
                Object.values(filters)
                  .flat()
                  .forEach(filter => params.delete(filter.filterParam))
                params.delete('page')
                return params
              })
            }}
          >
            Clear Filters
          </Button>
          <Button
            variant='outlined'
            size='small'
            onClick={close}
            sx={{ display: { mobile: 'initial', laptop: 'none' } }}
          >
            Done
          </Button>
        </Stack>
        <Stack spacing={3}>
          {children}
          {Object.keys(filters).map(filterTitle => (
            <Stack key={filterTitle}>
              <Typography gutterBottom variant='h6' sx={{ textTransform: 'capitalize' }}>
                {filterTitle}
              </Typography>
              {filters[filterTitle]?.map(filter => <Checkbox key={filter.title} filter={filter} />)}
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Form>
  )
}

function FilterChips({ filters }: FilterChipsProps) {
  const [params, setParams] = useSearchParams()

  return (
    <Grid container spacing={3}>
      {filters.map(filter => {
        if (params.has(filter.filterParam, filter.value)) {
          return (
            <Grid key={`${filter.filterParam}: ${filter.title}`}>
              <Chip
                label={filter.title}
                onDelete={() => {
                  setParams(params => {
                    params.delete(filter.filterParam, filter.value)
                    return params
                  })
                }}
                deleteIcon={<Close />}
                sx={{
                  bgcolor: 'grey.A100',
                  color: 'grey.500',
                  textTransform: 'capitalize',
                  '& .MuiChip-deleteIcon': { color: 'grey.500' },
                }}
              />
            </Grid>
          )
        }

        return null
      })}
    </Grid>
  )
}

//* --------------- TYPES ---------------
type FilterType = { title: string; filterParam: string; value: string }

type FiltersType = Record<string, FilterType[]>

interface UseFiltersProps {
  open?: boolean
  close?: () => void
  filters: FiltersType
}

interface ChildProps {
  children?: React.ReactNode | null
}

type FilterListProps = Omit<UseFiltersProps, 'open'> & ChildProps

interface FilterChipsProps {
  filters: FilterType[]
}

declare global {
  module TrinityAPI {
    type FiltersType = Record<string, FilterType[]>
  }
}
