import { Tabs as MuiTabs, Tab as MuiTab, styled } from '@mui/material'
import type { TabsProps, TabProps } from '@mui/material'

const Tabs = styled((props: TabsProps) => (
  <MuiTabs
    {...props}
    sx={{ border: 'n100', borderRadius: 100, height: 48 }}
    TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
  />
))({
  '& .MuiTabs-indicator': {
    height: '100%',
  },
})

const Tab = styled((props: TabProps) => <MuiTab {...props} />)(({ theme }) => ({
  paddingTop: theme.spacing(1.75),
  alignItems: 'flex-start',
  flexDirection: 'row',
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
  zIndex: 1,
  ...theme.typography.tab,
  '&.Mui-selected': {
    color: theme.palette.common.white,
  },
  '>.MuiSvgIcon-root': {
    marginTop: theme.spacing(-0.3),
    marginRight: theme.spacing(1),
  },
}))

export { Tabs, Tab }
