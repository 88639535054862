import { useEffect, useRef, useState } from 'react'
import { Form, useSearchParams } from 'react-router-dom'
import { subYears } from 'date-fns'
import {
  Autocomplete,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { downloadClientInsightCSV } from '@trinity/utils'
import { Pagination, Search } from '../../components'
import { useGlobalState } from '../../hooks'
import { ClientsTable } from './ClientsTable'

interface ClientInsightsProps {
  clients?: TrinityAPI.ClientDashboardType[]
  totalClients?: number
}

export function ClientInsights({ clients = [], totalClients = 0 }: ClientInsightsProps) {
  const { onMobile, user } = useGlobalState()
  const [hasSearched, setHasSearched] = useState(false)
  const scrollRef = useRef<null | HTMLDivElement>(null)
  const [params] = useSearchParams()

  useEffect(() => {
    if (scrollRef.current && hasSearched && clients.length > 0) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }, [clients, hasSearched])

  return (
    <Stack spacing={4}>
      <Typography variant='h2'>Client Insights</Typography>
      <Form method='get'>
        <Filters hasSearched={hasSearched} setHasSearched={setHasSearched} />
      </Form>
      {hasSearched && (
        <Stack ref={scrollRef} spacing={2} pt={8}>
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Typography variant='body2'>{totalClients} Clients</Typography>
            <Stack direction='row' spacing={2}>
              {!onMobile && (
                <Button download variant='outlined' href={downloadClientInsightCSV(user.token, params.toString())}>
                  Export
                </Button>
              )}
              <Search name='q' placeholder='Search Clients...' method='get' />
            </Stack>
          </Stack>
          <ClientsTable clients={clients} />
          <Pagination total={totalClients} pageSizeOptions={[100, 200, 300]} />
        </Stack>
      )}
    </Stack>
  )
}

function Filters({
  hasSearched,
  setHasSearched,
}: {
  hasSearched: boolean
  setHasSearched: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const [params, setParams] = useSearchParams()
  const [garmentValues, setGarmentValues] = useState<GarmentTag[]>(getDefaultGarmentTypes(params.get('garmentTypes')))
  const [haveBought, setHaveBought] = useState(params.get('haveBought') ?? 'true')
  const defaultStartDate = params.get('startDate')
  const defaultEndDate = params.get('endDate')
  const [startDate, setStartDate] = useState(defaultStartDate ? new Date(defaultStartDate) : subYears(new Date(), 1))
  const [endDate, setEndDate] = useState(defaultEndDate ? new Date(defaultEndDate) : new Date())

  const filterGarmentOptions = (_event: React.SyntheticEvent<Element, Event>, values: GarmentTag[]) => {
    if (values.some((tag: GarmentTag) => tag.name === 'Any Garments') && values.length > 1) {
      let newGarmentValues
      if (values[0]?.name === 'Any Garments') {
        newGarmentValues = values.filter((value: GarmentTag) => value.name !== 'Any Garments')
      } else {
        newGarmentValues = values.filter((value: GarmentTag) => value.name === 'Any Garments')
      }
      setGarmentValues(newGarmentValues)
    } else {
      if (values.length < 1) {
        setGarmentValues([GARMENT_TAGS[0]])
      } else {
        setGarmentValues(values)
      }
    }
  }

  useEffect(() => {
    setParams(
      params => {
        params.set(
          'garmentTypes',
          garmentValues
            .map(g => g?.garmentTypeBitmask ?? '')
            .flat()
            .join(','),
        )
        return params
      },
      { replace: true },
    )
  }, [garmentValues, setParams])

  useEffect(() => {
    setParams(
      params => {
        params.set('haveBought', haveBought)
        return params
      },
      { replace: true },
    )
  }, [haveBought, setParams])

  useEffect(() => {
    setParams(
      params => {
        params.set('startDate', startDate.toISOString())
        return params
      },
      { replace: true },
    )
  }, [startDate, setParams])

  useEffect(() => {
    setParams(
      params => {
        params.set('endDate', endDate.toISOString())
        return params
      },
      { replace: true },
    )
  }, [endDate, setParams])

  return (
    <Stack spacing={4}>
      <Stack direction={{ mobile: 'column', tablet: 'row' }} spacing={2}>
        <FormControl fullWidth variant='outlined' sx={{ width: { laptop: 1 / 5 } }}>
          <InputLabel sx={{ bgcolor: 'common.white' }}>Clients who have</InputLabel>
          <Select
            name='haveBought'
            labelId='client-who-select'
            value={haveBought}
            onChange={e => setHaveBought(e.target.value)}
            label='have-bought-select'
          >
            <MenuItem value='true'>Ordered</MenuItem>
            <MenuItem value='false'>Not Ordered</MenuItem>
          </Select>
        </FormControl>
        <input type='hidden' name='garmentTypes' value={garmentValues.map(g => g?.garmentTypeBitmask ?? '').flat()} />
        <Autocomplete
          multiple
          disableClearable
          filterSelectedOptions
          id='garment-list'
          options={GARMENT_TAGS}
          getOptionLabel={option => option.name}
          value={garmentValues}
          onChange={filterGarmentOptions}
          sx={{ width: { md: 3 / 5 } }}
          renderInput={params => <TextField {...params} variant='outlined' label='Garments' />}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              // eslint-disable-next-line react/jsx-key
              <Chip
                {...getTagProps({ index })}
                key={index}
                label={option.name}
                color={option.name === 'Any Garments' ? 'primary' : 'neutral'}
              />
            ))
          }
        />
        <Stack direction='row' spacing={2}>
          <DatePicker
            label='From'
            value={startDate}
            onChange={newDate => newDate && setStartDate(newDate)}
            slotProps={{
              textField: { helperText: 'MM/DD/YYYY', name: 'startDate' },
              openPickerIcon: { sx: { color: 'grey.900' } },
            }}
          />
          <DatePicker
            label='To'
            value={endDate}
            onChange={newDate => newDate && setEndDate(newDate)}
            slotProps={{
              textField: { helperText: 'MM/DD/YYYY', name: 'endDate' },
              openPickerIcon: { sx: { color: 'grey.900' } },
            }}
          />
        </Stack>
      </Stack>
      <Button
        type='submit'
        sx={{ width: { mobile: 1, laptop: 1 / 6 }, alignSelf: 'flex-end' }}
        onClick={() => setHasSearched(true)}
      >
        {hasSearched ? 'Update Results' : 'Find Clients'}
      </Button>
    </Stack>
  )
}

const GARMENT_TAGS = [
  { name: 'Any Garments', garmentTypeBitmask: null },
  { name: 'Suits', garmentTypeBitmask: ['5', '7', '21', '23'] },
  { name: 'Jackets', garmentTypeBitmask: ['1'] },
  { name: 'Pants', garmentTypeBitmask: ['4'] },
  { name: 'Vests', garmentTypeBitmask: ['2'] },
  { name: 'Shirts', garmentTypeBitmask: ['8'] },
  { name: 'Shorts', garmentTypeBitmask: ['64'] },
  { name: 'Swackets', garmentTypeBitmask: ['512'] },
  { name: 'Outerwear', garmentTypeBitmask: ['32'] },
] as const

function getDefaultGarmentTypes(garmentType?: string | null): GarmentTag[] {
  if (!garmentType) return [GARMENT_TAGS[0]]

  const garmentTypeArray = garmentType.split(',')

  return GARMENT_TAGS.filter(tag => tag.garmentTypeBitmask?.some(el => garmentTypeArray.includes(el)))
}

type GarmentTag = (typeof GARMENT_TAGS)[number]
