import { Box, styled } from '@mui/material'

export const GlobalContainer = styled(Box)(({ theme }) => ({
  maxWidth: '1440px',
  margin: '0 auto',
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(8),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(6),
  },
  // [theme.breakpoints.up('xl')]: {
  //   padding: theme.spacing(8),
  // },
}))
