import { Children, useState } from 'react'
import {
  Table as MuiTable,
  TableHead,
  TableBody,
  TableRow as MuiTableRow,
  TableCell,
  type TableProps as MuiTableProps,
  type TableRowProps as MuiTableRowProps,
  IconButton,
  Collapse,
} from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'

interface TableProps extends MuiTableProps {
  spacing?: number
  cellBorders?: boolean
}

export function Table({ spacing = 0.25, cellBorders = true, sx, ...props }: TableProps) {
  return (
    <MuiTable
      stickyHeader
      size='small'
      sx={{
        ...sx,
        borderSpacing: `0 ${spacing}rem`,
        '& .MuiTableHead-root > .MuiTableRow-root > .MuiTableCell-root': {
          backgroundColor: 'grey.100',
          lineHeight: 'normal',
          py: 0.75,
          zIndex: 0,
          textTransform: 'uppercase',
          typography: 'htable',
        },
        '& .MuiTableBody-root > .MuiTableRow-root > .MuiTableCell-root': cellBorders
          ? {
              ':first-of-type': { borderLeft: 'n100', borderBottomLeftRadius: 4, borderTopLeftRadius: 4 },
              ':last-child': { borderRight: 'n100', borderBottomRightRadius: 4, borderTopRightRadius: 4 },
              borderTop: 'n100',
              borderBottom: 'n100',
              typography: 'body1',
            }
          : { typography: 'body1' },
      }}
      {...props}
    />
  )
}

type TableRowProps = (
  | {
      isAccordion?: false
      ExpandedComponent?: undefined
    }
  | {
      isAccordion: true
      ExpandedComponent: React.ReactNode
    }
) &
  MuiTableRowProps

function TableRow({ children, isAccordion, ExpandedComponent, ...props }: TableRowProps) {
  const [open, setOpen] = useState(false)
  const count = Children.toArray(children).length

  if (!isAccordion) return <MuiTableRow {...props}>{children}</MuiTableRow>

  return (
    <>
      <MuiTableRow
        sx={{
          height: 80,
          boxShadow: theme => (open ? theme.elevation.accordian : null),
          '& > *': { borderBottom: open ? '' : 'unset !important' },
          '&:nth-of-type(4n - 1)': {
            backgroundColor: theme => theme.palette.action.hover,
          },
        }}
        {...props}
      >
        <TableCell>
          <IconButton size='small' onClick={() => setOpen(open => !open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        {children}
      </MuiTableRow>
      <MuiTableRow>
        <TableCell colSpan={count + 1} sx={{ py: 0 }}>
          <Collapse in={open} timeout='auto' unmountOnExit sx={{ my: 2 }}>
            {ExpandedComponent}
          </Collapse>
        </TableCell>
      </MuiTableRow>
    </>
  )
}

Table.Head = TableHead
Table.Body = TableBody
Table.Row = TableRow
Table.Cell = TableCell
