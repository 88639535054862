import { CssBaseline, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { type TrinityAppNames } from '@trinity/utils'
import { setTheme } from '../../theme'
import { GlobalProvider } from './GlobalProvider'

interface TrinityWrapperProps {
  appName: TrinityAppNames
  user: TrinityAPI.LoginType
  children: React.ReactNode
}

export function TrinityWrapper({ appName, user, children }: TrinityWrapperProps) {
  const theme = setTheme(appName)

  return (
    <ThemeProvider theme={theme}>
      <GlobalProvider user={user}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          {children}
        </LocalizationProvider>
      </GlobalProvider>
    </ThemeProvider>
  )
}

export function BaseTrinityWrapper({ appName, children }: Omit<TrinityWrapperProps, 'user'>) {
  const theme = setTheme(appName)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}
