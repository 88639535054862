import { useEffect, useState } from 'react'
import { Button, Dialog, MenuItem, Stack, TextField, ToggleButton, Typography } from '@mui/material'
import { Form, useLocation, useNavigate } from 'react-router-dom'
import { CA_PROVINCES, COUNTRIES, US_STATES } from '@trinity/utils'
import { ToggleButtonGroup } from '../../components'

interface ClientFormProps {
  title: string
  errors?: Record<string, unknown> | null
  client?: TrinityAPI.ClientExtendedType | null
}

export function ClientForm({ title, errors = {}, client = null }: ClientFormProps) {
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const allowDelete = location.pathname.endsWith('edit')

  useEffect(() => {
    if (errors) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [errors])

  return (
    <Form method='put'>
      <Stack spacing={4} width={{ mobile: 1, laptop: '35%' }}>
        <Typography variant='h2'>{title} Client</Typography>
        <GeneralInfo errors={errors} client={client} />
        <EmbroideryInfo client={client} />
        <AddressInfo client={client} />
        <ContactInfo errors={errors} client={client} />
        <Stack direction='row' spacing={2}>
          <Button type='submit'>Save</Button>
          <Button variant='outlined' onClick={() => navigate(-1)}>
            Cancel
          </Button>
          {allowDelete && (
            <>
              <Button color='error' onClick={() => setIsOpen(true)}>
                Delete
              </Button>
              <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <Form method='delete'>
                  <Stack spacing={6} p={2}>
                    <Typography variant='h6'>Are you sure you want to delete this customer?</Typography>
                    <Stack direction='row' justifyContent='center' spacing={4}>
                      <input type='hidden' name='name' value={client?.name ?? ''} />
                      <Button type='submit' color='error' onClick={() => setIsOpen(false)}>
                        Confirm
                      </Button>
                      <Button variant='outlined' onClick={() => setIsOpen(false)}>
                        Cancel
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              </Dialog>
            </>
          )}
        </Stack>
      </Stack>
    </Form>
  )
}

interface SectionHeaderProps {
  title: string
  children: React.ReactNode
}

function SectionHeader({ title, children }: SectionHeaderProps) {
  return (
    <>
      <Typography variant='body1' sx={{ fontWeight: 600 }}>
        {title}
      </Typography>
      <Stack spacing={4} pl={2}>
        {children}
      </Stack>
    </>
  )
}

function GeneralInfo({ errors, client }: Omit<ClientFormProps, 'title'>) {
  const [genderValue, setGenderValue] = useState(client?.gender ?? 'male')

  return (
    <SectionHeader title='General Info'>
      <TextField
        required
        size='small'
        name='name'
        label='Name'
        defaultValue={client?.name}
        error={Boolean(errors?.name)}
        helperText={errors?.name as string}
      />
      <input type='hidden' name='gender' value={genderValue} />
      <ToggleButtonGroup value={genderValue} onChange={(_e, v) => setGenderValue(v)} sx={{ width: 'fit-content' }}>
        <ToggleButton value='male'>Male</ToggleButton>
        <ToggleButton value='female'>Female</ToggleButton>
      </ToggleButtonGroup>
      <TextField size='small' name='company' label='Company' defaultValue={client?.company} />
    </SectionHeader>
  )
}

function EmbroideryInfo({ client }: Pick<ClientFormProps, 'client'>) {
  return (
    <SectionHeader title='Embroidery Info'>
      <TextField size='small' name='nameLabel' label='Name Label Text' defaultValue={client?.nameLabel} />
      <TextField size='small' name='laundryMarker' label='Laundry Marker Text' defaultValue={client?.laundryMarker} />
      <TextField size='small' name='monogram' label='Monogram Text' defaultValue={client?.monogram} />
    </SectionHeader>
  )
}

function AddressInfo({ client }: Pick<ClientFormProps, 'client'>) {
  const [addressTypeValue, setAddressTypeValue] = useState('residential')
  const [countryValue, setCountryValue] = useState(client?.country ?? 'USA')
  const stateValues = countryValue === 'USA' ? US_STATES : CA_PROVINCES
  const stateLabel = countryValue === 'USA' ? 'State' : 'Province'

  return (
    <SectionHeader title='Address Info'>
      <Stack spacing={1}>
        <Typography variant='body1' sx={{ fontWeight: 600 }}>
          Address Type
        </Typography>
        <input type='hidden' name='addressType' value={addressTypeValue} />
        <ToggleButtonGroup
          value={addressTypeValue}
          onChange={(_e, v) => setAddressTypeValue(v)}
          sx={{ width: 'fit-content' }}
        >
          <ToggleButton value='residential'>Residential</ToggleButton>
          <ToggleButton value='commercial'>Commercial</ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <Stack spacing={1}>
        <TextField size='small' name='address1' label='Address 1' defaultValue={client?.address1} />
        <TextField size='small' name='address2' label='Address 2' defaultValue={client?.address2} />
      </Stack>
      <Stack direction='row' spacing={2}>
        <TextField size='small' name='city' label='City' defaultValue={client?.city} sx={{ flex: 2 }} />
        {['USA', 'Canada'].includes(countryValue) && (
          <TextField
            select
            size='small'
            name='state'
            label={stateLabel}
            defaultValue={client?.state ?? ''}
            sx={{ flex: 1.25 }}
            SelectProps={{ MenuProps: { MenuListProps: { sx: { pb: 15 } } } }}
          >
            {stateValues.map(state => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
          </TextField>
        )}
        <TextField size='small' name='zip' label='Zip' defaultValue={client?.zip} sx={{ flex: 1 }} />
      </Stack>
      <TextField
        select
        size='small'
        label='country'
        name='country'
        value={countryValue}
        onChange={e => setCountryValue(e.target.value)}
        SelectProps={{ MenuProps: { MenuListProps: { sx: { pb: 15 } } } }}
      >
        {COUNTRIES.map(country => (
          <MenuItem key={country} value={country}>
            {country}
          </MenuItem>
        ))}
      </TextField>
    </SectionHeader>
  )
}

function ContactInfo({ errors, client }: Omit<ClientFormProps, 'title'>) {
  return (
    <SectionHeader title='Contact Info'>
      <Stack spacing={1}>
        <TextField type='tel' size='small' name='cellPhone' label='Cell Phone' defaultValue={client?.cell} />
        <TextField type='tel' size='small' name='homePhone' label='Home Phone' defaultValue={client?.homePhone} />
      </Stack>
      <TextField
        type='email'
        size='small'
        name='email'
        label='Email'
        defaultValue={client?.email}
        error={Boolean(errors?.email)}
        helperText={errors?.email as string}
      />
    </SectionHeader>
  )
}
