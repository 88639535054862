import { Chip, type ChipProps } from '@mui/material'
import { FabricOrderStatus, GarmentStatus, Shipment } from '@trinity/utils'

interface StatusChipProps extends ChipProps {
  status: string
}

export function StatusChip({ status, sx, ...props }: StatusChipProps) {
  const styles = getStyles(status)

  return <Chip label={status} sx={{ height: 26, typography: 'smallBody1', ...styles, ...sx }} {...props} />
}

function getStyles(status: string) {
  switch (status) {
    case FabricOrderStatus.ACCEPTED:
      return { bgcolor: 'success.extraLight', color: 'success.main', fontWeight: 600 }
    case GarmentStatus.CANCELED:
    case FabricOrderStatus.CANCELLED:
    case GarmentStatus.INCOMPLETE:
      return { bgcolor: 'error.extraLight', color: 'error.main', fontWeight: 600 }
    case FabricOrderStatus.RECEIVED:
    case Shipment.Status.RECEIVED:
      return { bgcolor: 'success.main', color: 'success.contrastText' }
    case FabricOrderStatus.REJECTED:
    case GarmentStatus.FABRIC_HOLD:
    case GarmentStatus.CMT_FABRIC_HOLD:
      return { bgcolor: 'error.main', color: 'error.contrastText' }
    case GarmentStatus.READY:
      return { bgcolor: 'warning.main', color: 'warning.contrastText' }
    case GarmentStatus.BLUE_PENCIL:
    case GarmentStatus.CUTTING:
    case GarmentStatus.PRODUCTION:
    case GarmentStatus.PRODUCTION_COMPLETE:
      return { bgcolor: 'info.main', color: 'info.contrastText' }
    case GarmentStatus.DELIVERY:
    case GarmentStatus.DIRECT_SHIP:
      return { bgcolor: 'grey.75', color: 'grey.700', fontWeight: 600 }
    default:
      return { bgcolor: 'neutral.main', color: 'neutral.contrastText' }
  }
}
