// import { Children, cloneElement } from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'

type ViewProps = React.ComponentProps<typeof View>
type TextProps = React.ComponentProps<typeof Text>
type Style = ViewProps['style']

export function Table({ style, ...props }: ViewProps) {
  const styleProps = getStyles(styles.table, style)

  return <View style={styleProps} {...props} />
}

export function TableHeadRow({ style, ...props }: ViewProps) {
  const styleProps = getStyles(styles.tableHead, style)

  return <View fixed style={[styles.tableRow, ...styleProps]} {...props} />
}

export function TableBodyRow({ style, ...props }: ViewProps) {
  const styleProps = getStyles(styles.tableRow, style)

  return <View wrap={false} style={styleProps} {...props} />
}

export function TableHeadCell({ width, style, ...props }: TextProps & { width: number }) {
  const styleProps = getStyles(styles.tableCell, style as Style)

  return (
    <View style={[styles.tableColumn, { width: `${width}%` }]}>
      <Text style={[{ fontWeight: 700 }, ...styleProps]} {...props} />
    </View>
  )
}

export function TableCell({ width, style, ...props }: TextProps & { width: number }) {
  const styleProps = getStyles(styles.tableCell, style as Style)

  return (
    <View style={[styles.tableColumn, { width: `${width}%` }]}>
      <Text style={styleProps} {...props} />
    </View>
  )
}

//! @TODO:  Work on this to handle automatically setting a width on the children
// {Children.map(
//   children,
//   (child, index) =>
//     child &&
//     typeof child !== 'string' &&
//     typeof child !== 'number' &&
//     typeof child !== 'boolean' &&
//     cloneElement(child, { key: index, style: styles.tableColumn }),
// )}
// {Array.isArray(children) ? children.map((child, index) => <View key={index}>{child}</View>) : children}

const styles = StyleSheet.create({
  table: {
    width: 'auto',
  },
  tableHead: {
    backgroundColor: '#e1e2eb',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColumn: {
    borderStyle: 'solid',
    borderWidth: 1,
    // backgroundColor: '#e1e2eb',
  },
  tableHeaderCell: {
    // maxWidth: 55,
    textAlign: 'center',
    margin: 4,
    fontSize: 10,
    fontWeight: 700,
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 10,
    margin: 4,
  },
})

const getStyles = <T extends Style>(defaultStyles: T, styles: Style) => {
  if (styles === undefined) {
    return [defaultStyles]
  }

  if (Array.isArray(styles)) {
    return [defaultStyles, ...styles]
  }

  return [defaultStyles, styles]
}
