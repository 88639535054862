import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Breadcrumbs,
  Button,
  CardMedia,
  Chip,
  Grid,
  IconButton,
  MobileStepper,
  Popover,
  Stack,
  type SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { GetApp, Help, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { downloadFabricImage, formatDate } from '@trinity/utils'
import { ImageNotFound } from '../../assets'
import { useGlobalState } from '../../hooks'
import { FabricFavorite } from './FabricFavorite'
import { FabricCard } from './FabricCard'
import { NotifyMe } from './NotifyMe'

interface FabricExplorerFabricProps {
  fabric: TrinityAPI.FabricFullType
  relatedFabrics: TrinityAPI.FabricExtendedType[]
}

export function FabricExplorerFabric({ fabric, relatedFabrics }: FabricExplorerFabricProps) {
  return (
    <Stack spacing={4}>
      <Stack direction='row' justifyContent='space-between'>
        <Stack direction='row' alignItems='center' spacing={{ mobile: 0, desktop: 4 }}>
          <CardMedia
            component='img'
            image={fabric.collection?.image ?? ImageNotFound}
            alt={fabric.collection?.name}
            height={100}
            sx={{ display: { mobile: 'none', laptop: 'initial' } }}
          />
          <div>
            <Stack direction='row' alignItems='flex-start' spacing={1}>
              <Typography gutterBottom noWrap variant='h4' sx={{ pt: { mobile: 1, tablet: 0.5, desktop: 0 } }}>
                {fabric.trinityFabricNumber}
              </Typography>
              <FabricFavorite isLarge fabric={fabric} />
            </Stack>
            <Typography noWrap variant='body1'>
              {fabric.description}
            </Typography>
          </div>
        </Stack>
        <Breadcrumbs aria-label='breadcrumb' sx={{ display: { mobile: 'none', laptop: 'initial' } }}>
          <Typography component={Link} to='/fabric-explorer/collections' variant='smallLink'>
            Collections
          </Typography>
          {fabric.collection && (
            <Typography
              component={Link}
              to={`/fabric-explorer/collections/${fabric.collection.id}`}
              variant='smallLink'
            >
              {fabric.collection.title}
            </Typography>
          )}
          <Typography variant='smallBody1'>{fabric.trinityFabricNumber}</Typography>
        </Breadcrumbs>
      </Stack>
      <Stack direction={{ mobile: 'column', laptop: 'row' }} spacing={{ mobile: 6, laptop: 12 }}>
        <FabricImages fabric={fabric} />
        <FabricBodyColumnOne fabric={fabric} />
        <FabricBodyColumnTwo fabric={fabric} />
      </Stack>
      <Typography variant='h4' sx={{ pt: 8 }}>
        Related Fabrics
      </Typography>
      <Grid container justifyContent={{ mobile: 'center', laptop: 'flex-start' }} columnGap={5} rowGap={5}>
        {relatedFabrics.map(fabric => (
          <Grid item key={fabric.id}>
            <FabricCard fabric={fabric} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  )
}

//* HELPER COMPONENTS
function FabricBodyColumnOne({ fabric }: Pick<FabricExplorerFabricProps, 'fabric'>) {
  return (
    <Stack spacing={6}>
      <DefaultSection title='collection' displayText={fabric.collection?.name ?? 'No Collection'} />
      <DefaultSection title='introduced' shouldDisplay={Boolean(fabric.fabricYear)} displayText={fabric.fabricYear} />
      <DefaultSection
        title='garment types'
        shouldDisplay={fabric.fabricGarmentTypes.length > 0}
        displayText={fabric.fabricGarmentTypes.join(', ')}
      />
      <DefaultSection
        title='trim garment types'
        shouldDisplay={fabric.trimGarmentTypes.length > 0}
        displayText={fabric.trimGarmentTypes.join(', ')}
      />
      {fabric.relatedTrim && (
        <Stack spacing={2}>
          <Typography variant='h6' fontSize='label.fontSize'>
            RELATED TRIM
          </Typography>
          <Typography component={Link} to={`../${fabric.relatedTrim.id}`} variant='link'>
            {fabric.relatedTrim.trinityFabricNumber}
          </Typography>
        </Stack>
      )}
      <Factory fabric={fabric} />
    </Stack>
  )
}

function FabricBodyColumnTwo({ fabric }: Pick<FabricExplorerFabricProps, 'fabric'>) {
  return (
    <Stack spacing={6}>
      <DefaultSection title='composition' displayText={fabric.composition.description.toLowerCase()} />
      <DefaultSection
        title='weight'
        shouldDisplay={Boolean(fabric.weight)}
        displayText={fabric.weight === 0 ? 'Unknown' : `${fabric.weight} g/m`}
      />
      <DefaultSection
        title='pattern'
        shouldDisplay={Boolean(fabric.fabricPattern?.name)}
        displayText={fabric.fabricPattern?.name}
      />
      <DefaultSection
        title='weave'
        shouldDisplay={Boolean(fabric.fabricWeave?.name)}
        displayText={fabric.fabricWeave?.name}
      />
      <DefaultSection title='price' displayText={fabric.priceTier ? '$'.repeat(Number(fabric.priceTier)) : 'Unknown'} />
      {fabric.discount && (
        <Chip
          color='secondary'
          label={`${Math.ceil(fabric.discount)}% off`}
          sx={{ borderRadius: 1, mt: '0.5rem !important', width: 'fit-content' }}
        />
      )}
      <Availability factories={fabric.factories} />
    </Stack>
  )
}

function FabricImages({ fabric }: Pick<FabricExplorerFabricProps, 'fabric'>) {
  const { onMobile } = useGlobalState()
  const { user } = useGlobalState()
  const defaultImage = fabric.hasImage ? fabric.url : ImageNotFound
  const [imageUrl, setImageUrl] = useState(defaultImage)
  const isSwatch = imageUrl === fabric.url

  useEffect(() => {
    setImageUrl(defaultImage)
  }, [defaultImage])

  if (onMobile) {
    const images = [defaultImage]
    fabric.alternateImages?.forEach(image => images.push(image.url))
    const findImage = (imageUrl: string) => images.indexOf(imageUrl)

    return (
      <Stack>
        <CardMedia
          image={imageUrl}
          sx={{
            height: { mobile: 320, laptop: 400 },
            width: { mobile: 320, laptop: 400 },
            backgroundSize: isSwatch ? 140 : 'contain',
            backgroundRepeat: isSwatch ? 'repeat' : 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
        <MobileStepper
          steps={images.length}
          position='static'
          activeStep={findImage(imageUrl)}
          sx={{
            mt: -6.5,
            width: { mobile: 320, laptop: 400 },
            boxShadow: theme => theme.elevation.fabricImage,
            background: 'none',
            '&>.MuiMobileStepper-dots': {
              position: 'relative',
              top: theme => theme.spacing(6),
              '&>.MuiMobileStepper-dot': { bgcolor: 'grey.300' },
              '&>.MuiMobileStepper-dotActive': { bgcolor: 'common.black' },
            },
          }}
          nextButton={
            <IconButton
              size='small'
              onClick={() => setImageUrl(prevImage => images[findImage(prevImage) + 1] ?? defaultImage)}
              disabled={findImage(imageUrl) === images.length - 1}
              sx={{ color: 'common.white', '&:disabled': { color: 'grey.700' } }}
            >
              <KeyboardArrowRight />
            </IconButton>
          }
          backButton={
            <IconButton
              size='small'
              onClick={() => setImageUrl(prevImage => images[findImage(prevImage) - 1] ?? defaultImage)}
              disabled={findImage(imageUrl) === 0}
              sx={{ color: 'common.white', '&:disabled': { color: 'grey.700' } }}
            >
              <KeyboardArrowLeft />
            </IconButton>
          }
        />
      </Stack>
    )
  }

  return (
    <Stack spacing={2} sx={{ width: { mobile: 320, laptop: 400 } }}>
      <CardMedia
        image={imageUrl}
        sx={{
          height: { mobile: 320, laptop: 400 },
          width: { mobile: 320, laptop: 400 },
          backgroundSize: isSwatch ? 140 : 'contain',
          backgroundRepeat: isSwatch ? 'repeat' : 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        {isSwatch && <Box sx={sawtoothMaskStyles} />}
      </CardMedia>
      <Stack alignItems='center' spacing={2}>
        <Button
          download
          disabled={!fabric.hasImage}
          size='small'
          variant='outlined'
          color='neutral'
          startIcon={<GetApp />}
          href={downloadFabricImage(fabric.id, user.token, fabric.trinityFabricNumber, imageUrl)}
        >
          Download Image
        </Button>
        <Stack direction='row' spacing={2}>
          <Typography
            variant='smallBody1'
            sx={fabricButtonStyles(imageUrl, defaultImage)}
            onClick={() => setImageUrl(defaultImage)}
          >
            Swatch
          </Typography>
          {fabric.alternateImages?.map(image => (
            <Typography
              key={image.name}
              variant='smallBody1'
              sx={fabricButtonStyles(imageUrl, image.url)}
              onClick={() => setImageUrl(image.url)}
            >
              {image.name}
            </Typography>
          ))}
        </Stack>
      </Stack>
    </Stack>
  )
}

interface DefaultSectionProps {
  title: string
  shouldDisplay?: boolean
  displayText?: string | number | null
}

function DefaultSection({ title, shouldDisplay = true, displayText = null }: DefaultSectionProps) {
  if (shouldDisplay) {
    return (
      <Stack spacing={2}>
        <Typography variant='h6' fontSize='label.fontSize' sx={{ textTransform: 'uppercase' }}>
          {title}
        </Typography>
        <Typography variant='body1' sx={{ textTransform: 'capitalize' }}>
          {displayText}
        </Typography>
      </Stack>
    )
  }

  return null
}

function Factory({ fabric }: Pick<FabricExplorerFabricProps, 'fabric'>) {
  return (
    <Stack spacing={1}>
      <Typography variant='h6' fontSize='label.fontSize'>
        FACTORY
      </Typography>
      <Typography component='span' variant='body1'>
        T2iD
        <Chip
          size='small'
          label={getLabel(fabric.factories.t2iD)}
          color={getColor(fabric.factories.t2iD)}
          sx={{ ml: 2, mr: 1.5, borderRadius: 1, typography: 'label' }}
        />
        {getRestockDate(fabric.factories.t2iD)}
      </Typography>
      <Typography component='span' variant='body1'>
        iD
        <Chip
          size='small'
          label={getLabel(fabric.factories.iD)}
          color={getColor(fabric.factories.iD)}
          sx={{ ml: 4.5, mr: 1.5, borderRadius: 1, typography: 'label' }}
        />
        {getRestockDate(fabric.factories.iD)}
      </Typography>
      <NotifyMe fabric={fabric} />
    </Stack>
  )
}

function Availability({ factories }: { factories: TrinityAPI.FactoryType }) {
  const { onMobile } = useGlobalState()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  return (
    <Stack spacing={1}>
      <Stack direction='row' alignItems='center'>
        <Typography variant='h6' fontSize='label.fontSize'>
          READINESS
        </Typography>
        <IconButton size='small' onClick={e => setAnchorEl(e.currentTarget)}>
          <Help sx={{ fontSize: 16, color: 'grey.300' }} />
        </IconButton>
      </Stack>
      {Object.keys(factories).map(factoryName => (
        <Typography key={factoryName} variant='body1' sx={{ textTransform: 'capitalize' }}>
          {`${factoryName} - ${factories[factoryName as keyof TrinityAPI.FactoryType]?.readiness}`}
        </Typography>
      ))}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        marginThreshold={onMobile ? 16 : 200}
        onClose={() => setAnchorEl(null)}
      >
        <AvailabilityPopover />
      </Popover>
    </Stack>
  )
}

//* HELPER FUNCTIONS
const sawtoothMaskStyles: SxProps = {
  width: '100%',
  height: '100%',
  backgroundImage:
    'linear-gradient(135deg, #FFF 50%, transparent 50%), linear-gradient(225deg, #FFF 50%, transparent 50%), linear-gradient(45deg, #FFF 50%, transparent 50%), linear-gradient(-45deg, #FFF 50%, transparent 50%), linear-gradient(135deg, #FFF 50%, transparent 50%), linear-gradient(45deg, #FFF 50%, transparent 50%), linear-gradient(-135deg, #FFF 50%, transparent 50%), linear-gradient(-45deg, #FFF 50%, transparent 50%)',
  backgroundPosition: 'top left, top left, bottom left, bottom left, top left, top left, top right, top right',
  backgroundSize: '10px 10px',
  backgroundRepeat: 'repeat-x, repeat-x, repeat-x, repeat-x, repeat-y, repeat-y, repeat-y, repeat-y',
}

const fabricButtonStyles = (currentImage: string, url: string): SxProps => ({
  color: currentImage === url ? 'common.white' : 'grey.500',
  border: currentImage === url ? 'primary' : 'n100',
  borderRadius: 1,
  cursor: 'pointer',
  bgcolor: currentImage === url ? 'primary.main' : 'common.white',
  px: 2,
  py: 1,
})

type FactoryType = TrinityAPI.FactoryType[keyof TrinityAPI.FactoryType]

const getLabel = (factory?: FactoryType) => (!factory ? 'Not Available' : factory.inStock ? 'Available' : 'Temp Out')
const getColor = (factory?: FactoryType) => (!factory ? 'error' : factory.inStock ? 'success' : 'warning')
const getRestockDate = (factory?: FactoryType) => factory?.restockDate && formatDate(factory.restockDate)

const AvailabilityPopover = () => (
  <Stack sx={{ p: 2, overflow: 'auto' }}>
    <Typography gutterBottom variant='h6'>
      Readiness
    </Typography>
    <Typography variant='body2'>
      Our fastest In Stock fabrics are ready right now. Single Cut fabrics need to be sent to our factory before your
      garment can be made.
    </Typography>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Delivery time for clothing</TableCell>
          <TableCell>Delivery time for shirts</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>At Factory</TableCell>
          <TableCell>Ready Now</TableCell>
          <TableCell>4-5 weeks</TableCell>
          <TableCell>2-3 weeks</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Ship From Vendor</TableCell>
          <TableCell>Not yet at the factory</TableCell>
          <TableCell>5-6 weeks</TableCell>
          <TableCell>3-5 weeks</TableCell>
        </TableRow>
      </TableBody>
    </Table>
    <Typography variant='body2' style={{ marginTop: '1rem' }}>
      Temp Out fabrics are currently unavailable. They have been reordered but will not be restocked for some time.
    </Typography>
  </Stack>
)
