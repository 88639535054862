import { MenuItem, Select, type SelectProps } from '@mui/material'

type CreditCardSelectProps = SelectProps & {
  creditCards: TrinityAPI.CreditCardType[]
}

export function CreditCardSelect({ creditCards, ...props }: CreditCardSelectProps) {
  return (
    <Select {...props}>
      {creditCards.map(card => (
        <MenuItem key={card.token} value={card.token}>
          {`${card.cardType} Ending in ${card.last4}`}
        </MenuItem>
      ))}
    </Select>
  )
}
