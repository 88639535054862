import { type Theme } from '@mui/material'

export const getElevation = (theme: Theme) => ({
  autocomplete: '0 4px 7px 0 rgba(20,20,43,0.12)',
  accordian: `0 22px 39px -25px ${theme.palette.grey[900]}`,
  search: '0 20px 30px -20px rgba(50,50,91,0.5)',
  card: '0 10px 25px 0 rgba(50,50,91,0.2)',
  card2: '0 4px 15px 0 rgba(20,20,43,0.15)',
  mobile: '0 -15px 20px -20px rgba(50,50,91,0.5)',
  divider: `inset 0 -1px 0 0 ${theme.palette.grey[100]}`,
  avatar: '0 10px 40px 0 rgba(50,50,91,0.4)',
  button: '0 4px 8px 0 rgba(50,50,91,0.2)',
  fabricImage: 'inset 0 -40px 30px 0 rgba(0,0,0,0.4)',
  selectedButton: '0px 3px 10px rgba(0, 0, 0, 0.05)',
})

declare module '@mui/material/styles' {
  interface Theme {
    elevation: {
      autocomplete: string
      accordian: string
      search: string
      card: string
      card2: string
      mobile: string
      divider: string
      avatar: string
      button: string
      fabricImage: string
      selectedButton: string
    }
  }

  interface ThemeOptions {
    elevation?: {
      autocomplete?: string
      accordian?: string
      search?: string
      card?: string
      card2?: string
      mobile?: string
      divider?: string
      avatar?: string
      button?: string
      fabricImage?: string
      selectedButton?: string
    }
  }
}
