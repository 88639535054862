import { type Theme, createTheme, responsiveFontSizes } from '@mui/material'
import type { TrinityAppNames } from '@trinity/utils'
import { getBorders } from './borders'
import { breakpoints } from './breakpoints'
import { getElevation } from './elevation'
import { palette } from './palette'
import { getTypography } from './typography'
import { Accordion, AccordionSummary } from './accordions'
import { getButtons } from './buttons'
import { TextField } from './textfield'

//* ---------- SETUP ----------
const getTheme = (theme: Theme) => {
  let newTheme = createTheme(theme, {
    typography: getTypography(theme),
    borders: getBorders(theme),
    elevation: getElevation(theme),
  })

  newTheme = createTheme(newTheme, {
    components: {
      MuiButton: getButtons(newTheme),
    },
  })

  return newTheme
}

//* ---------- DEFAULT THEME ----------
const defaultTheme = createTheme({
  palette,
  typography: {
    fontFamily: ['Montserrat', 'Arial', 'sans-serif', '-apple-system'].join(','),
    fontSize: 16,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
  spacing: (factor: number) => `${0.5 * factor}rem`,
  breakpoints,
  components: {
    MuiAccordion: Accordion,
    MuiAccordionSummary: AccordionSummary,
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiTextField: TextField,
  },
})

//* ---------- SKIFFLE ----------
const skiffleTheme = createTheme(defaultTheme, {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: '#323456',
    },
  },
})

//* ---------- SUPPLIER ----------
const supplierTheme = createTheme(defaultTheme, {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      extraLight: '#EBF7F1',
      light: '#45BA7D',
      main: '#17A95D',
      dark: '#107641',
    },
  },
})

//* ---------- ADMIN ----------
const adminTheme = createTheme(defaultTheme, {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      extraLight: '#E9F4FD',
      light: '#4EA0E4',
      main: '#2289DE',
      dark: '#175F9B',
    },
  },
})

//* ---------- EXPORTS ----------
// Used to handle returning the correct theme
export function setTheme(appName: TrinityAppNames) {
  let theme = defaultTheme
  if (appName === 'skiffle') {
    theme = skiffleTheme
  } else if (appName === 'supplier') {
    theme = supplierTheme
  } else if (appName === 'admin') {
    theme = adminTheme
  }

  theme = getTheme(theme)

  return responsiveFontSizes(theme)
}

export * from './borders'
export * from './breakpoints'
export * from './buttons'
export * from './elevation'
export * from './palette'
export * from './typography'
