import { useRouteError } from 'react-router-dom'
import { Stack, Typography } from '@mui/material'
import { logToSentry } from '@trinity/utils'

const ERROR_MESSAGE = 'dynamically imported module'

export function ErrorBoundary() {
  const error = useRouteError()
  const handleReload = () => window.location.reload()

  //* Implementing a workaround for the vite issue where modules are not imported correctly
  //* https://github.com/vitejs/vite/issues/11804
  if (error instanceof Error && error.message.toLocaleLowerCase().includes(ERROR_MESSAGE.toLocaleLowerCase())) {
    setTimeout(() => {
      handleReload()
    }, 1000)
  } else {
    logToSentry(error)
  }

  return (
    <Stack>
      <Typography variant='h4'>I'm sorry, it looks like something went wrong:</Typography>
      {error instanceof Error && <Typography variant='body1'>{error.message}</Typography>}
    </Stack>
  )
}
